export const EQUITY_LOAD_DATA = "EQUITY_LOAD_DATA";
export const EQUITY_LOAD_DATA_SUCCESS = "EQUITY_LOAD_DATA_SUCCESS";
export const EQUITY_LOAD_DATA_ERROR = "EQUITY_LOAD_DATA_ERROR";

export const EQUITY_UPDATE_PROPERTIES = "EQUITY_UPDATE_PROPERTIES";
export const EQUITY_UPDATE_PROPERTIES_SUCCESS = "EQUITY_UPDATE_PROPERTIES_SUCCESS";

export const EQUITY_UPDATE_PERIOD = "EQUITY_UPDATE_PERIOD";

export const EQUITY_ADD_PERIOD = "EQUITY_ADD_PERIOD";
export const EQUITY_ADD_PERIOD_SUCCESS = "EQUITY_ADD_PERIOD_SUCCESS";
export const EQUITY_REMOVE_PERIOD = "EQUITY_REMOVE_PERIOD";

export const EQUITY_TAB_SELECT = "EQUITY_TAB_SELECT";

export const EQUITY_INSTRUMENT_DETAILS = "EQUITY_INSTRUMENT_DETAILS";
export const EQUITY_INSTRUMENT_DETAILS_SUCCESS = "EQUITY_INSTRUMENT_DETAILS_SUCCESS";
export const EQUITY_REMOVE_DETAILS_TAB = "EQUITY_REMOVE_DETAILS_TAB";