import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocalization } from "@progress/kendo-react-intl";
import { ExpansionPanel, ExpansionPanelContent, } from "@progress/kendo-react-layout";
import { Reveal } from "@progress/kendo-react-animation";

import { LOADER } from "../../../infrastructure/localizationKeys";
import { payoutsLoad } from "../../../store/reducers/payoutsReducer/creators";
import LoaderComponent from "../../views/LoaderComponent";
import { payoutsPropertiesUpdate } from "../../../store/reducers/payoutsReducer/creators";
import PayoutsProperties from "./PayoutsProperties";
import { } from "../../../infrastructure/localizationKeys";

const PayoutsM = () => {
    const dispatch = useDispatch();
    const localization = useLocalization();
    const loading = useSelector(state => state.payouts.loading);
    const loaded = useSelector(state => state.payouts.loaded);
    const properties = useSelector(state => state.payouts.properties);
    const payouts = useSelector(state => state.payouts.data);

    useEffect(() => {
        if (!loading && !loaded)
            dispatch(payoutsLoad())
    })

    const handleUpdate = (properties) => {
        dispatch(payoutsPropertiesUpdate(properties));
    }

    const columns = [
        "january",
        "february",
        "march",
        "april",
        "may",
        "june",
        "july",
        "august",
        "september",
        "october",
        "november",
        "december"
    ]
    const uperCaseFirst = (str) => str[0].toUpperCase() + str.slice(1);
    const getMonthLocalizationKey = (month) => month === "total" ? TOTAL : `Common_Months_${uperCaseFirst(month)}`;

    const [expanded, setExpanded] = useState([]);

    const style = {
        marginTop: "10px",
        border: "1px solid darkgrey",
        borderRadius: "5px"
    }

    const getMonthValue = (props) => {
        return props;
    }

    const getColor = (value) => {
        if (value < 0)
            return "red";
        if (value > 0)
            return "green"
        return "";
    }

    const getTotal = (data) => {
        const style = {
            color: getColor(data.value)
        }
        return <div style={style}>{data.string}</div>
    }

    return loading ?
        <LoaderComponent description={localization.toLanguageString(LOADER)} />
        :
        <>
            <PayoutsProperties data={properties} onUpdate={handleUpdate} />
            {payouts.map(data =>
                <ExpansionPanel
                    title={data.year}
                    subtitle={getTotal(data.totalData)}
                    expanded={expanded.includes(data.year)}
                    tabIndex={0}
                    key={data.year}
                    style={style}
                    onAction={(event) => {
                        setExpanded(event.expanded ? expanded.filter(y => y != data.year) : [...expanded, data.year]);
                    }}>
                    <Reveal>
                        {expanded.includes(data.year) && (
                            <ExpansionPanelContent>
                                {columns.map(x => {
                                    const value = getMonthValue(data[`${x}Data`].string);
                                    return (
                                        value === ""
                                            ? null
                                            : <div key={x}>
                                                <label style={{ textAlign: "left", width: "50%", paddingBottom: "5px" }}>{localization.toLanguageString(getMonthLocalizationKey(x))}</label>
                                                <label style={{ textAlign: "right", width: "50%", paddingBottom: "5px", color: getColor(getMonthValue(data[`${x}Data`].value)) }}>{value}</label>
                                            </div>);
                                }
                                )}
                            </ExpansionPanelContent>
                        )}
                    </Reveal>
                </ExpansionPanel>
            )}

        </>;
}

export default PayoutsM;