import { isMobile } from 'react-device-detect';

import EquityD from "./EquityD";
import EquityM from "./EquityM";

const Equity = () => {
    return (
        isMobile ? <EquityM /> : <EquityD />
    );
}

export default Equity;