import { fork, all, takeEvery, select } from 'redux-saga/effects';
import { EQUITY_LOAD_DATA, EQUITY_UPDATE_PROPERTIES, EQUITY_UPDATE_PERIOD, EQUITY_ADD_PERIOD, EQUITY_INSTRUMENT_DETAILS } from "../../reducers/equityReducer/actions"
import {
    loadEquityError, loadEquitySuccess,
    equityPropertiesUpdateSuccess,
    equityAddPeriodSuccess,
    equityInstrumentDetailsSuccess
} from "../../reducers/equityReducer/creators";
import { requset } from "../infrastructure";

const baseUrl = "/api/equity";
const propertyUrl = `${baseUrl}/property`
const periodUrl = `${baseUrl}/period`
const addPeriodUrl = (period) => `${periodUrl}/?periodType=${period.periodType}&start=${period.start}&end=${period.end}`
const instrumentDetailsUrl = `${baseUrl}/instrumentDetails`
const addPeriodIstrumentDetailsUrl = (period) => `${instrumentDetailsUrl}/?symbol=${period.symbol}&start=${period.start}&end=${period.end}`
// WORKER SAGAS
function* load() {
    yield requset(baseUrl, "GET", loadEquitySuccess, loadEquityError);
}
function* update({ payload }) {
    const customPeriods = yield select(state => state.equity.customPeriods);
    const result = { properties: payload, customPeriods }
    yield requset(propertyUrl, "PUT", equityPropertiesUpdateSuccess, null, result);
}
function* updatePeriod({ payload }) {
    yield requset(periodUrl, "PUT", null, null, payload);
}
function* addPeriod({ payload }) {
    let { type, start, end } = payload;

    if (start.length === 10)
        start = `${start}T00:00:00`;
    else
        start = start.substr(0, 10) + "T00:00:00"

    if (end.length === 10)
        end = `${end}T00:00:00`;
    else
        end = end.substr(0, 10) + "T00:00:00"

    const existingPeriod = yield select(state =>
        state.equity.customPeriods.find(x =>
            x.periodType === type && x.start === start && x.end === end));

    if (existingPeriod)
        return;

    const period = {
        periodType: type,
        start,
        end
    }
    yield requset(addPeriodUrl(period), "GET", equityAddPeriodSuccess);
}

function* loadInstrumentDetails({ payload }) {
    let { symbol, start, end } = payload;
    const period = {
        symbol,
        start,
        end
    }
    yield requset(addPeriodIstrumentDetailsUrl(period), "GET", equityInstrumentDetailsSuccess);
}

// WATCHER SAGAS
function* loadSaga() {
    yield takeEvery(EQUITY_LOAD_DATA, load);
}
function* updateSaga() {
    yield takeEvery(EQUITY_UPDATE_PROPERTIES, update);
}
function* updatePeriodSaga() {
    yield takeEvery(EQUITY_UPDATE_PERIOD, updatePeriod);
}
function* addPeriodSaga() {
    yield takeEvery(EQUITY_ADD_PERIOD, addPeriod);
}
function* loadInstrumentDetailsSaga() {
    yield takeEvery(EQUITY_INSTRUMENT_DETAILS, loadInstrumentDetails);
}

// ROOT SAGA
export default function* equitySaga() {
    yield all([
        fork(loadSaga),
        fork(updateSaga),
        fork(updatePeriodSaga),
        fork(addPeriodSaga),
        fork(loadInstrumentDetailsSaga),
    ]);
}