import { LOGIN, LOGIN_LOGOUT_ERROR, REMOVE_TOKEN_FROM_STORAGE, CHECK_VALIDATION, LOGIN_LOGOUT_CLEAR_ERROR, DEMO } from "./actions";
import { LOGOUT } from "../generalReducer/generalActions";

const initial = {
    authorize: false,
    storageChecking: false,
    storageChecked: false,

    name: "",
    role: "",
    token: "",
    connection: undefined,

    error: null,
    demoLoading: false,
}

function loginReducer(state = initial, action) {
    switch (action.type) {
        case DEMO:
            return {
                ...state,
                demoLoading: true,
            }

        case LOGIN:
            return {
                ...state,

                storageChecked: true,
                storageChecking: false,
                authorize: true,

                ...action.payload
            }
        case LOGOUT:
            return {
                ...initial,
                connection: state.connection,
                storageChecked: true
            }
        case LOGIN_LOGOUT_ERROR:
            return {
                ...state,
                error: action.payload
            }
        case LOGIN_LOGOUT_CLEAR_ERROR:
            return {
                ...state,
                error: null,
            }

        case REMOVE_TOKEN_FROM_STORAGE:
            localStorage.removeItem("token")
            return {
                ...state,
                storageChecked: true,
                storageChecking: false,
                authorize: false,
                token: null
            }
        case CHECK_VALIDATION:
            return {
                ...state,
                storageChecking: true,
            }
        default:
            return state;
    }
}

export default loginReducer;