import { useState } from "react";
import { Button } from "@progress/kendo-react-buttons";
import { Dialog } from "@progress/kendo-react-dialogs";

import ObjectEditor from "../../views/ObjectEditor";

const ProfitFixerProperties = (props) => {

    const [visibleModal, setVisibleModal] = useState(false);

    const styleLine = {
        display: "inline-block",
        width: "-webkit-fill-available"
    }

    const updateHandler = (result) => {
        props.onUpdate(result);
    }

    const styleButton = {
        width: "auto",
        height: "30px",
        fontSize: "90%",
        marginLeft: "5px",
        float: "right"
    }

    const toggleDialog = () => {
        setVisibleModal(!visibleModal);
    };

    const DialogModal = () => {
        return (visibleModal &&
            <Dialog title=" " modal={true} onClose={toggleDialog} resizable={true} width={window.innerWidth - 10}>
                <ObjectEditor data={props.data} onUpdate={updateHandler} orientation="vertical" />
            </Dialog>
        );
    }

    return (
        <div style={styleLine}>
            <Button style={styleButton} icon="k-icon k-i-gear" onClick={toggleDialog}></Button>
            <DialogModal />
        </div>
    );

}

export default ProfitFixerProperties;