import { useDispatch, useSelector } from "react-redux";
import { hideLinks } from "../../../store/reducers/dashboardReducer/creators";
import { Notification, NotificationGroup } from "@progress/kendo-react-notification";
import { Slide } from "@progress/kendo-react-animation";

const LinksInfo = () => {
    const dispatch = useDispatch();

    const handlerHideInfoLinks = () => dispatch(hideLinks());


    const linksUsedDataSources = useSelector(state => state.dashboard.linksUsedDataSources);

    const groupStyle = {
        bottom: 15,
        left: 18,
        zIndex: 1,
        
        fontWeight: "bold",
    };


    const style = (side) => ({
        width: "110%",
        textAlign: side,
        float: side,
        marginLeft: "-20px",
});

    return (
        <NotificationGroup style={groupStyle}>
            <Slide direction={"down"}>

                <Notification style={{ background: "khaki"}} closable={true} onClose={handlerHideInfoLinks}>
                    <div className="content-in-page">
                        <label className="dasboard-text-links-used-data" style={style("left")}>
                            {linksUsedDataSources}
                        </label>
                    </div>
                </Notification>
            </Slide>
        </NotificationGroup>
    );

}
export default LinksInfo;