import { DropDownList } from "@progress/kendo-react-dropdowns";

const CollectionField = (props) => {

    const handleChange = (event) => {
        props.onPropertyEdit(props.propertyName, event.target.value);
    }

    const style = {
        width: props.width
    }

    return (
        <DropDownList
            disabled={props.disabled}
            onChange={handleChange}
            data={props.collection}
            value={props.value}
            style={style}
        />
    )
}

export default CollectionField;