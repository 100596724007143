import { Input } from "@progress/kendo-react-inputs";

const InputField = (props) => {
    const value = props.value || "";
    const type = props.propertyType.includes("String") ? "text" : "number"

    const handleChange = (event) => {
        const value = type === "number" ? Number(event.value) : event.value;
        props.onPropertyEdit(props.propertyName, value);
    }

    const style = {
        width: props.width
    }
    
    return (
        <Input
            disabled={props.disabled}
            value={value}
            type={type}
            onChange={handleChange}
            style={style}
            step={props.step}
            min={props.minvalue}
            max={props.maxvalue}
        />
    )
}

export default InputField;