import { useSelector } from "react-redux";
import { useState } from "react";
import { ExpansionPanel, ExpansionPanelContent, } from "@progress/kendo-react-layout";
import { Reveal } from "@progress/kendo-react-animation";

const InstrumentPanel = () => {
    const money = useSelector(state => state.equity.money);

    const [expanded, setExpanded] = useState([]);

    const style = {
        fontSize: "85%",
        marginTop: "10px",
        border: "1px solid darkgrey",
        borderRadius: "5px",
        position: "relative"
    }
    const styleDetails = {
        marginTop: "10px",
        marginLeft: "-10px",
        marginRight: "-10px",
        border: "1px solid darkgrey",
        borderRadius: "5px",
        position: "relative",
        padding: "5px 5px 0px 5px"
    }

    const getColor = (value) => {
        if (value < 0)
            return "red";
        if (value > 0)
            return "green"
        return "";
    }

    const getTotal = (data) => {
        const style = {
            color: getColor(data.totalValue)
        }
        return <div style={style}>{data.totalValueString}</div>
    }

    const StringDateTime = (props) => {
        const value = props.split("T");
        return value[0];
    }

    return (money.length > 0
        ? money.map(data =>
            <ExpansionPanel
                title={data.type}
                subtitle={getTotal(data)}
                expanded={expanded.includes(data.type)}
                tabIndex={0}
                key={data.type}
                style={style}
                onAction={(event) => {
                    setExpanded(event.expanded ? expanded.filter(y => y != data.type) : [...expanded, data.type]);
                }}>
                <Reveal>
                    {expanded.includes(data.type) && (
                        <ExpansionPanelContent style={{ marginTop: "-15px" }}>
                            {data.events.map((event, i) =>
                                <div key={i} style={styleDetails}>
                                    <label style={{ textAlign: "left", width: "50%", paddingBottom: "5px" }}>{StringDateTime(event.DateTime)}</label>
                                    <label style={{ textAlign: "right", width: "50%", paddingBottom: "5px" }}>{event.ShortName}</label>
                                    <label style={{ textAlign: "left", width: "50%", paddingBottom: "5px" }}>{event.Number}</label>
                                    <label style={{ textAlign: "right", width: "50%", paddingBottom: "5px" }}>{event.ValueString}</label>
                                </div>
                            )}

                        </ExpansionPanelContent>
                    )}
                </Reveal>
                
            </ExpansionPanel>
        )
        : null
    );
}

//{
//    !expanded.includes(data.type) && data.cells.RUB && data.cells.USD &&
//        <div>
//            <label style={{ textAlign: "left", width: "50%", paddingBottom: "15px", paddingLeft: "20px" }}>{data.cells.RUB?.Amount} {data.cells.RUB?.CurrencySymbol}</label>
//            <label style={{ textAlign: "right", width: "50%", paddingBottom: "15px", paddingRight: "53px" }}>{data.cells.USD?.Amount} {data.cells.USD?.CurrencySymbol}</label>
//        </div>
//}

export default InstrumentPanel;