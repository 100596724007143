import {
    IDEAS_LOAD_DATA, IDEAS_LOAD_DATA_SUCCESS, IDEAS_LOAD_DATA_ERROR,
    ADD_IDEA, ADD_IDEA_SUCCESS, DEACTIVATE_IDEA_SUCCESS, IDEA_UPDATE_SUCCESS,
    IDEA_ADD_NOTE, IDEA_ADD_NOTE_SUCCESS, UPDATE_NOTE_SUCCESS,
    CHANGE_SYMBOL, CHANGE_NOTE,
    SELECT_IDEA, SELECT_NOTE,
} from "./actions";

import { LOGOUT, CLEAR_ALL, UPDATE_ALL_DATA } from "../generalReducer/generalActions";

const initial = {
    loaded: false,
    loading: false,
    error: false,

    activeIdeas: [],
    symbol: "",
    selectedIdeaId: undefined,
    textNote: "",
    selectedNoteId: undefined,
    isUpdateNote: false,
    openPopup: false,
};


function ideasReducer(state = initial, action) {
    switch (action.type) {
        // LOAD IDEAS
        case IDEAS_LOAD_DATA:
            return {
                ...state,

                loading: true,
                error: false,
            }
        case IDEAS_LOAD_DATA_SUCCESS:
            const ideas = action.payload;
            return {
                ...state,

                loaded: true,
                loading: false,
                error: false,

                activeIdeas: ideas,
                selectedIdeaId: ideas.length? ideas[0].id : undefined,
            }
        case IDEAS_LOAD_DATA_ERROR:
            return {
                ...state,

                loaded: true,
                loading: false,
                error: true
            }

        // ADD IDEA
        case ADD_IDEA:
            return {
                ...state,
                symbol: ""
            }

        case ADD_IDEA_SUCCESS:
            return {
                ...state,
                activeIdeas: [...state.activeIdeas, action.payload]
            }

        // DEACTIVATE IDEA
        case DEACTIVATE_IDEA_SUCCESS:
            let nextIndex = 0;
            const updatedActiveIdeas = state.activeIdeas.filter((x, i) => {
                if (x.id === action.payload) {
                    nextIndex = i - 1;
                    return false;
                } else {
                    return true;
                }
            });
            if (updatedActiveIdeas.length > 0) {
                if (nextIndex < 0)
                    nextIndex = 0;
                return {
                    ...state,
                    activeIdeas: updatedActiveIdeas,
                    selectedIdeaId: updatedActiveIdeas[nextIndex].id
                }
            }
            else {
                return {
                    ...state,
                    activeIdeas: [],
                    selectedIdeaId: undefined
                }
            }
        
        // UPDATE IDEA
        case IDEA_UPDATE_SUCCESS:
            return {
                ...state,
                activeIdeas: state.activeIdeas.map(x => x.id === action.payload.id ? action.payload : x),
                isUpdateNote: false,
            }

        // ADD NOTE
        case IDEA_ADD_NOTE:
            return {
                ...state,
                textNote: "",
                isUpdateNote: false,
            }
        case IDEA_ADD_NOTE_SUCCESS:
            return {
                ...state,
                textNote: "",
                isUpdateNote: false,
                activeIdeas: state.activeIdeas.map(idea => idea.id === action.payload.idIdea ? { ...idea, notes: [...idea.notes, action.payload.ideaNote] } : idea)
            }

        // UPDATE NOTE
        case UPDATE_NOTE_SUCCESS:
            return {
                ...state,
                activeIdeas: state.activeIdeas.map(idea => idea.id === action.payload.idIdea ? { ...idea, notes: idea.notes.map(note => note.noteDbEntity === action.payload.ideaNote.noteDbEntity ? action.payload.ideaNote : note) } : idea),
                textNote: "",
                isUpdateNote: false,
            }

        // LOCAL ACTIONS
        case CHANGE_SYMBOL:
            return {
                ...state,
                symbol: action.payload
            }
        case CHANGE_NOTE:
            return {
                ...state,
                textNote: action.payload
            }
        case SELECT_NOTE:
            return {
                ...state,
                textNote: action.payload.text,
                selectedNoteId: action.payload.noteDbEntity,
                isUpdateNote: true,
            }
        case SELECT_IDEA:
            return {
                ...state,
                selectedIdeaId: action.payload,
                isUpdateNote: false,
                textNote: "",
            }
        case UPDATE_ALL_DATA:
            return {
                ...state,
                loading: false,
                loaded: false
            }
        case CLEAR_ALL:
            return initial;
        case LOGOUT:
            return initial;
        default:
            return state;
    }
}

export default ideasReducer;