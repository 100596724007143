import {
    DASHBOARD_LOAD_DATA, DASHBOARD_LOAD_DATA_SUCCESS, DASHBOARD_LOAD_DATA_ERROR,
    DASHBOARD_INFO_LINKS_HIDE,
    DASHBOARD_GENERATE_DEMO_DATA,
    DASHBOARD_PROGRESS_CHANGE,
    DASHBOARD_TODAY_CHANGE_UPDATE
} from "./actions";

// LOAD DATA
export const loadInfo = () => ({
    type: DASHBOARD_LOAD_DATA,
})
export const loadInfoSuccess = (data) => ({
    type: DASHBOARD_LOAD_DATA_SUCCESS,
    payload: data,
})
export const loadInfoError = () => ({
    type: DASHBOARD_LOAD_DATA_ERROR,
})

// HIDE LINKS
export const hideLinks = () => ({
    type: DASHBOARD_INFO_LINKS_HIDE,
})

// GENERATE DATA
export const dashboardGenerateDemoData = () => ({
    type: DASHBOARD_GENERATE_DEMO_DATA,
})

// PROGRESS CHANGE
export const dashBoardProgressChange = (data) => ({
    type: DASHBOARD_PROGRESS_CHANGE,
    payload: data,
})


export const dasboardTodayeChangeUpdate = (data) => ({
    type: DASHBOARD_TODAY_CHANGE_UPDATE,
    payload: data,
})