import {
    EQUITY_LOAD_DATA, EQUITY_LOAD_DATA_ERROR, EQUITY_LOAD_DATA_SUCCESS,
    EQUITY_UPDATE_PROPERTIES, EQUITY_UPDATE_PROPERTIES_SUCCESS,
    EQUITY_UPDATE_PERIOD,
    EQUITY_ADD_PERIOD, EQUITY_ADD_PERIOD_SUCCESS, EQUITY_REMOVE_PERIOD,
    EQUITY_TAB_SELECT,
    EQUITY_INSTRUMENT_DETAILS,
    EQUITY_INSTRUMENT_DETAILS_SUCCESS,
    EQUITY_REMOVE_DETAILS_TAB,
} from "./actions";

// LOAD EQUITY
export const loadEquity = (data) => ({
    type: EQUITY_LOAD_DATA,
})
export const loadEquitySuccess = (data) => ({
    type: EQUITY_LOAD_DATA_SUCCESS,
    payload: data,
})
export const loadEquityError = (data) => ({
    type: EQUITY_LOAD_DATA_ERROR,
    payload: data,
})

// PROPERTIES UPDATE
export const equityPropertiesUpdate = (data) => ({
    type: EQUITY_UPDATE_PROPERTIES,
    payload: data
})
export const equityPropertiesUpdateSuccess = (data) => ({
    type: EQUITY_UPDATE_PROPERTIES_SUCCESS,
    payload: data
})

// PERIOD UPDATE
export const equityPeriodUpdate = (data) => ({
    type: EQUITY_UPDATE_PERIOD,
    payload: data
})

// PERIOD ADD/REMOVE
export const equityAddPeriod = (type, start, end) => ({
    type: EQUITY_ADD_PERIOD,
    payload: {
        type,
        start,
        end,
    }
})
export const equityAddPeriodSuccess = (data) => ({
    type: EQUITY_ADD_PERIOD_SUCCESS,
    payload: data
})
export const equityRemovePeriod = (data) => ({
    type: EQUITY_REMOVE_PERIOD,
    payload: data
})

// TAB SELECTED
export const equityTabSelect = (tab) => ({
    type: EQUITY_TAB_SELECT,
    payload: tab
})

export const getInstrumentDetails = (symbol, start, end) => ({
    type: EQUITY_INSTRUMENT_DETAILS,
    payload: {
        symbol,
        start,
        end
    }
});

// INSTRUMENT DETAILS TAB ADDD/REMOVE
export const equityInstrumentDetailsSuccess = (data) => ({
    type: EQUITY_INSTRUMENT_DETAILS_SUCCESS,
    payload: data
});

export const equityRemoveInstrumentDetails = (data) => ({
    type: EQUITY_REMOVE_DETAILS_TAB,
    payload: data
})
