export const dateToString = (date) => {
    if (!date)
        return null
    return `${date.getFullYear()}-${normalizeNumber(date.getMonth() + 1)}-${normalizeNumber(date.getDate())}`
}

const normalizeNumber = (number) => {
    if (number > 9)
        return number

    return `0${number}`
}

export const getDeclension = (number) => {
    const firstDigit = number % 10;
    const secondDigit = number % 100 - firstDigit;

    if (secondDigit === 10 || firstDigit > 4 || firstDigit === 0)
        return 2;
    if (firstDigit === 1)
        return 0;
    return 1;
}
