import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useLocalization } from "@progress/kendo-react-intl";
import ProgressComponent from "../../views/ProgressComponent";
import { Button } from '@progress/kendo-react-buttons';
import { Upload } from "@progress/kendo-react-upload";
import { Dialog } from "@progress/kendo-react-dialogs";

import {
    LOADER, ACCOUNT_IMPORT_STATEMENT_MOBILE, ACCOUNT_CLEAR_MOBILE, DASHBOARD_IMPORT_STATEMENT_HEADER,
    CONFIRM, YES, NO, ACCOUNT_YOU_SURE_DELETE_ACCOUNTS, LOAD_STATEMENT_HELP
} from "../../../infrastructure/localizationKeys";

import { loadAccounts, clearAllData } from "../../../store/reducers/accountsReducer/creators";
import { updateAllData } from "../../../store/reducers/generalReducer/generalCreators";
import LoaderComponent from "../../views/LoaderComponent";
import DescriptionPanel from "./Account/DescriptionPanelMobile";
const saveUrl = "/api/dashboard/upload";

const AccountM = () => {

    const localization = useLocalization();
    const dispatch = useDispatch();
    const [visibleModal, setVisibleModal] = useState(false);
    const [visibleModalDelete, setVisibleModalDelete] = useState(false);

    const accounts = useSelector(state => state.accounts);

    const { data, loading, loaded, progress, error, processing } = accounts;

    const token = useSelector(state => state.login.token);
    const saveHeaders = { Authorization: "Bearer " + token }

    const toggleDialog = () => {
        setVisibleModal(!visibleModal);
    };

    const toggleDialogDelete = () => {
        setVisibleModalDelete(!visibleModalDelete);
    };

    useEffect(() => {
        if (!loading && !loaded)
            dispatch(loadAccounts());
    })



    const DialogModal = () => {

        const UploadComponent = (
            <>
                <div className="link-instruction-report-mobile">
                    <a href="https://investortools.ru/help/statements" target="_blank">{localization.toLanguageString(LOAD_STATEMENT_HELP)}</a>
                </div>
                <Upload
                    disabled={processing}
                    accept=".csv,application/vnd.ms-excel,.xml,.html"
                    autoUpload={false}
                    multiple={true}
                    batch={true}
                    onStatusChange={toggleDialog}
                    actionsLayout="start"
                    defaultFiles={[]}
                    saveUrl={saveUrl}
                    saveHeaders={saveHeaders}
                />
            </>
        );
        return (
            visibleModal &&
            <Dialog title={localization.toLanguageString(DASHBOARD_IMPORT_STATEMENT_HEADER)} modal={true} onClose={toggleDialog} resizable={true} width={window.innerWidth - 10}>
                {UploadComponent}
            </Dialog>

        );
    }

    const clearAll = () => {
        setVisibleModalDelete(!visibleModalDelete);
        dispatch(clearAllData());

    }

    const DialogModalDelete = () => {
        return (
            visibleModalDelete &&
            <Dialog title={localization.toLanguageString(CONFIRM)} modal={true} onClose={toggleDialogDelete} resizable={true} width={window.innerWidth - 10}>
                <h6>{localization.toLanguageString(ACCOUNT_YOU_SURE_DELETE_ACCOUNTS)}</h6>
                <Button onClick={toggleDialogDelete} className="btn-dialog">
                    {localization.toLanguageString(NO)}
                </Button>
                <Button onClick={clearAll} className="btn-dialog">
                    {localization.toLanguageString(YES)}
                </Button>
            </Dialog>
        );
    }



    if (progress != undefined)
        if (!progress.isProcessing && progress.currentStep === progress.stepsCount)
            dispatch(updateAllData());

    return <>
        {loading ? (<LoaderComponent description={localization.toLanguageString(LOADER)} />) : (
            <>
                <Button className="accounts-import-report btn btn-outline-primary" onClick={toggleDialog} disabled={progress}>
                    {localization.toLanguageString(ACCOUNT_IMPORT_STATEMENT_MOBILE)}
                </Button>
                <Button className="accounts-clear btn btn-outline-primary" onClick={toggleDialogDelete} disabled={progress}>
                    {localization.toLanguageString(ACCOUNT_CLEAR_MOBILE)}
                </Button>
                {progress &&
                    <div className="account-progress">
                        <ProgressComponent progress={progress} error={error} />
                    </div>}
                <DialogModal />
                <DialogModalDelete />
                {data.map((e) => {
                    return (<DescriptionPanel
                        key={e.id}
                        number={e.number}
                        broker={e.broker}
                        holder={e.holder} />
                    );
                })}
            </>
        )}
    </>
}

export default AccountM;