import { fork, all, takeEvery } from 'redux-saga/effects';
import { PROFIT_FIXER_LOAD_DATA, PROFIT_FIXER_PROPERTIES_UPDATE } from "../../reducers/profitFixerReducer/actions";
import { profitFixerLoadSuccess, profitFixerLoadError } from "../../reducers/profitFixerReducer/creators";
import { requset } from "../infrastructure";

const baseUrl = "/api/profitfixer";
const propertyUrl = `${baseUrl}/property`;

//WORKER SAGAS
function* load() {
    yield requset(baseUrl, "GET", profitFixerLoadSuccess, profitFixerLoadError);
}
function* update({ payload }) {
    yield requset(propertyUrl, "PUT", profitFixerLoadSuccess, null, payload);
}

//WATCHER SAGAS
function* loadProfitFixerSaga() {
    yield takeEvery(PROFIT_FIXER_LOAD_DATA, load);
}
function* propertiesUpdateSaga() {
    yield takeEvery(PROFIT_FIXER_PROPERTIES_UPDATE, update);
}

//ROOT SAGA
export default function* profitFixerSaga() {
    yield all([
        fork(loadProfitFixerSaga),
        fork(propertiesUpdateSaga)
    ]);
}