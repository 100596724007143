import { useSelector, useDispatch } from "react-redux";
import { useState } from "react";
import ObjectEditor from "../../views/ObjectEditor";
import { portfolioSetProperties } from "../../../store/reducers/portfolioReducer/creators";
import { isMobile } from 'react-device-detect';

const PortfolioProperties = (props) => {
    const dispatch = useDispatch();
    const properties = useSelector(state => state.portfolio.portfolioProperties);
    const handleUpdate = (result) => dispatch(portfolioSetProperties(result));

    const [width, setWidth] = useState((window.innerWidth - 60) / 2);

    const resizeWindow = () => {
        setWidth((window.innerWidth - 60) / 2);
    }
    
    return <>
        {window.addEventListener('resize', resizeWindow, false)}
        {isMobile ? <ObjectEditor data={properties} onUpdate={handleUpdate} orientation="horizontal" width={width} />
            : <ObjectEditor data={properties} onUpdate={handleUpdate} orientation="horizontal" />
        }
    </>
}

export default PortfolioProperties;