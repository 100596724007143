import { Grid, GridColumn as Column } from "@progress/kendo-react-grid";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router";

import { DropDownCell } from "../../views/DataGridCells";

import { updateUser, deleteUser, editUserEnter, cancelEditUser, editUser } from "../../../store/reducers/usersReducer/creators";

const editField = "inEdit";

const UsersGrid = () => {
    const dispatch = useDispatch();
    const data = useSelector(state => state.users.list);

    const itemChange = (event) => {
        const id = event.dataItem.id;
        const field = event.field || "";
        const value = event.value;

        dispatch(editUser(id, field, value));
    };

    return (
        <Grid
            className="admin-table"
            data={data}
            editField={editField}
            onItemChange={itemChange}
        >
            <Column field="name" title="Name" />
            <Column field="password" title="Password" />
            <Column field="role" title="Role" cell={DropDownRoleCell} />
            <Column cell={TableCommandCell} width="240px" />
        </Grid>
    )
}

const TableCommandCell = (props) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { dataItem } = props;
    const inEdit = dataItem[editField];

    const enterEdit = (dataItem) => {
        dispatch(editUserEnter(dataItem.id));
    };

    const update = (dataItem) => {
        dispatch(updateUser(dataItem));
    };
    const deleteItem = (dataItem) => {
        dispatch(deleteUser(dataItem.id));
    }
    const cancel = (dataItem) => {
        dispatch(cancelEditUser(dataItem.id));
    }

    const userStat = () => {
        navigate(`/user/${dataItem.id}`);
    }

    const baseClass = "k-button k-button-md k-rounded-md k-button-solid-base";
    const primaryClass = "k-button k-button-md k-rounded-md k-button-solid-primary";

    return inEdit ? (
        <td className="k-command-cell">
            <button
                className={baseClass}
                onClick={() => update(dataItem)}
            >
                Update
            </button>
            <button
                className={baseClass}
                onClick={() => cancel(dataItem)}
            >
                Cancel
            </button>
        </td>
    ) : (
            <td className="k-command-cell">
                <button
                    className={primaryClass}
                    onClick={() => enterEdit(dataItem)}
                >
                    Edit
                </button>
                <button
                    className={primaryClass}
                    onClick={() => window.confirm(`Delete '${dataItem.name}' user?`) && deleteItem(dataItem) }
                >
                    Delete
                </button>

                <button
                    className={primaryClass}
                    onClick={() => userStat(dataItem)}
                >
                    Statistic
                </button>

            </td>
    );
};

const DropDownRoleCell = (props) => {
    const data = [{
        text: "Admin",
        value: "Admin",
    }, {
        text: "User",
            value: "User",
        }
    ];
    return (<DropDownCell {...props} data={data} width = "100px" />);
};

export default UsersGrid;