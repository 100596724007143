import { useSelector } from "react-redux";
import { useLocalization } from "@progress/kendo-react-intl";

import { PORTFOLIO_PIECES }
    from "../../../infrastructure/localizationKeys";

const MobilePanelLastExecutions = () => {

    const localization = useLocalization();

    const data = useSelector(state => state.restorePositions.data);
    const sortedData = data.sort((a, b) => a.quantity < b.quantity ? 1 : -1);

    const percentFormat = new Intl.NumberFormat("en", { style: "percent", minimumFractionDigits: 2, maximumFractionDigits: 2 });
    const quantityFormat = new Intl.NumberFormat("en", { style: "decimal", maximumFractionDigits: 2 });

    const style = {
        marginTop: "5px",
        border: "1px solid darkgrey",
        borderRadius: "5px",
        padding: "10px 10px 5px 10px"
    }

    const styleLabelLeft = {
        color: "dodgerblue",
        textAlign: "left",
        width: "60%",
        paddingBottom: "5px",
        fontSize: "95%"
    }

    const styleLabelRight = {
        textAlign: "right",
        width: "40%",
        paddingBottom: "5px",
        fontSize: "90%"
    }

    const styleLabelTime = {
        textAlign: "left",
        width: "60%",
        paddingBottom: "5px",
        fontSize: "80%"
    }

    const styleLabelPrice = {
        textAlign: "right",
        width: "40%",
        paddingBottom: "5px",
        fontSize: "80%"
    }

    const dateTimeToString = (props) => {
        const value = props.split("T");
        const date = value[0];
        const time = value[1].split(".")[0];

        return [date, time].join(" ");
    }

    const getColor = (value) => {
        if (value < 0)
            return "red";
        if (value > 0)
            return "green";
        return "";
    }

    return (
        <div style={{ marginTop: "10px" }}>
            {sortedData.map((x, i) => {
                const colorPercent = {
                    color: getColor(x.priceChange)
                }
                return (
                    <div style={style} key={i}>
                        <label style={styleLabelLeft}>
                            {x.instrument.Name} <label style={{ color: "black", fontSize: "95%" }}> <b>⋅</b> {quantityFormat.format(x.quantity)} {localization.toLanguageString(PORTFOLIO_PIECES)}</label>
                        </label>
                        <label style={styleLabelRight}>
                            {x.uncoveredLossDecorator}
                        </label>
                        <label style={styleLabelTime}>
                            {dateTimeToString(x.executionTime)} <b>⋅</b> {x.executionPriceDecorator}
                        </label>
                        <label style={styleLabelPrice}>
                            {x.marketPriceDecorator} (<label style={colorPercent}>{percentFormat.format(x.priceChange)}</label>)
                        </label>
                    </div>
                )
            })}
        </div>
    );
}

export default MobilePanelLastExecutions;