import { useLocalization } from "@progress/kendo-react-intl";
import { useDispatch, useSelector } from "react-redux";
import { Grid, GridColumn as Column } from "@progress/kendo-react-grid";
import { RightAlignCell, SparkLineCell } from "../../../views/DataGridCells";
import { SYMBOL, NAME, TOTAL, REALIZED_PL, UNREALIZED_PL, PL, EQUITY_TABLE_COLUMN_DIVIDENS_AND_COUPONS } from "../../../../infrastructure/localizationKeys";
import { getPositiveNegativeColor, getBlackWhiteColor } from "../../../../infrastructure/gradientPercentToColor";
import { getInstrumentDetails } from "../../../../store/reducers/equityReducer/creators"

const InstrumentTab = (props) => {
    const dispatch = useDispatch();
    const localization = useLocalization();
    const columns = [
        {
            field: "RealizedPl",
            title: localization.toLanguageString(REALIZED_PL)
        },
        {
            field: "UnrealizedPl",
            title: localization.toLanguageString(UNREALIZED_PL)
        },
        {
            field: "DividendsAndCoupons",
            title: localization.toLanguageString(EQUITY_TABLE_COLUMN_DIVIDENS_AND_COUPONS)
        },
        {
            field: "Total",
            title: localization.toLanguageString(TOTAL)
        }
    ]

    const handleSelectedInstrument = (event) => {
        dispatch(getInstrumentDetails(event.dataItem.Symbol, props.start, props.end));
    };
    let classname = props.classname + " cursor-pointer";
    
    return (
        <Grid data={props.data} style={{ height: props.height }} className={classname} onRowClick={handleSelectedInstrument}>
            <Column field="Symbol" title={localization.toLanguageString(SYMBOL)}/>
            <Column field="ShortName" title={localization.toLanguageString(NAME)}/>
            {columns.map(x => <Column key={x.field} {...x} cell={ColoredCell} />)}
            <Column field="SparklineData" width="255" title={localization.toLanguageString(PL)} cell={(props) => <SparkLineCell {...props} width={255}/>} />
        </Grid>
    );
}

const ColoredCell = (props) => {
    const offset = props.dataItem[`${props.field}CellGradientOffset`];
    const color = getBlackWhiteColor(offset)
    const background = getPositiveNegativeColor(offset);

    return (
        <RightAlignCell
            {...props}
            field={`${props.field}String`}
            style={{ color, background }}
        />
    )
}

export default InstrumentTab;