import {
    Chart,
    ChartLegend,
    ChartSeries,
    ChartSeriesItem,
    ChartTitle,
    ChartSeriesItemTooltip,
    ChartTooltip
} from "@progress/kendo-react-charts";
import { useSelector } from "react-redux";

import ListLegend from "./ListLegend";


const labelContent = (props) => {
    if (props.percentage < 0.05)
        return "";
    let formatedNumber = Number(props.percentage).toLocaleString(undefined, {
        style: "percent",
        minimumFractionDigits: 2,
    });
    return `${formatedNumber}`;
};

const nestedTooltipRender = ({ point }) => {

    let percentage = Number(point?.percentage).toLocaleString(undefined, {
        style: "percent",
        minimumFractionDigits: 2,
    });
    let formatedNumber = Number(point?.value).toLocaleString(undefined, {
        style: "decimal",
        minimumFractionDigits: 2,
    });
    return (
        <span>
            <b>{point?.category}</b>
            <br />
            <b>{percentage}</b>
            <br />
            <b>{formatedNumber}</b>
        </span>
    );
}

const defaultTooltipRender = ({ point }) => {
    return `Default Content ${point?.value}`;
}

const getColor = ( count ) => {

    const colors = [
        "#00AAFF", "#98dd94", "#ccff99", "#f5f0f7", "#f9f0e0", "#feabab", "#45b3e0", "#87ceeb", "#c9e9f6", "#84ced1",
        "#006599", "#67d482", "#eeffbb", "#ebdcf3", "#ffe7af", "#feeaea", "#40b0d0", "#82cbe8", "#d9f906", "#d3f0e3"
    ];
    if (count >= colors.length)
        return "#000000".replace(/0/g, function () { return (~~(Math.random() * 16)).toString(16); });
    else
       return colors[count];
}


const PieChart = ({ name, data }) => {

    const isVisibleLinksInfo = useSelector(state => state.dashboard.info?.isVisibleLinksInfo);

    const newData = data?.map((x, i) => ({
        name: x.name,
        value: x.value,
        color: getColor(i),
    }))
    
    //let dashboardChartBorderClassName = isVisibleLinksInfo ? "dashboard-chart-border-min" : "dashboard-chart-border";
    //let dashboardChartClassName = isVisibleLinksInfo ? "dashboard-chart-min" : "dashboard-chart";
    let listLegendHeightClassName = isVisibleLinksInfo ? "list-legend-height-min" : "list-legend-height";
    return (
        <div className="dashboard-chart-border">
            <Chart className="dashboard-chart" transitions={false}>
                <ChartTitle text={name} visible={false}/>
                <ChartTooltip render={defaultTooltipRender} />
                <ChartLegend position="right" orientation="vertical" visible={false}/>
                <ChartSeries>
                    <ChartSeriesItem
                        type="pie"
                        data={newData}
                        categoryField="name"
                        padding = {15}
                        labels={{
                            visible: true,
                            content: labelContent,
                            background: "transparent",
                            position: "center"
                        }}
                    >
                        <ChartSeriesItemTooltip render={nestedTooltipRender} />
                    </ChartSeriesItem>
                </ChartSeries>
            </Chart>
            <p style={{
                padding: "5px",
                margin: "0px",
                color: "black"
            }}> {name} </p>
            <ListLegend data={newData} className="dashboard-chart-legend"/>
        </div>
    );
}

export default PieChart;