import { put, fork, delay } from 'redux-saga/effects';
import { update } from "../../reducers/generalReducer/generalCreators";

// WORKER SAGAS
function* updateProcess() {
    yield put(update());
}

// WATCHER SAGAS
function* updateSaga() {
    while (true) {
        yield delay(200);
        yield updateProcess();
    }
}

// ROOT SAGA
export default function* updateRootSaga() {
    yield fork(updateSaga);
}