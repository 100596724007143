import { fork, all, takeEvery } from 'redux-saga/effects';
import { RESTORE_POSITIONS_LOAD_DATA, RESTORE_POSITIONS_PROPERTIES_UPDATE } from "../../reducers/restorePositionsReducer/actions";
import { restorePositionsLoadSuccess, restorePositionsLoadError } from "../../reducers/restorePositionsReducer/creators";
import { requset } from "../infrastructure";

const baseUrl = "/api/restorepositions"
const propertyUrl = `${baseUrl}/property`;

//WORKER SAGAS
function* load() {
    yield requset(baseUrl, "GET", restorePositionsLoadSuccess, restorePositionsLoadError);
}

function* update({ payload }) {
    yield requset(propertyUrl, "PUT", restorePositionsLoadSuccess, null, payload);
}

//WATCHER SAGAS
function* loadRestorePositionsSaga() {
    yield takeEvery(RESTORE_POSITIONS_LOAD_DATA, load);
}

function* propertiesUpdateSaga() {
    yield takeEvery(RESTORE_POSITIONS_PROPERTIES_UPDATE, update);
}

//ROOT SAGA
export default function* restorePositionsSaga() {
    yield all([
        fork(loadRestorePositionsSaga),
        fork(propertiesUpdateSaga)
    ])
}