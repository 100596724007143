import {
    CORRELATION_LOAD_DATA,
    CORRELATION_LOAD_DATA_ERROR,
    CORRELATION_LOAD_DATA_SUCCESS,
    CORRELATION_PROPERTIES_UPDATE,
    INITIAL_CORRELATION_TABLE,
    UPDATE_CORRELATION_DATA,
    UPDATE_STATUS_LOADER,
    CUSTOM_SORT
} from "./actions";

export const correlationLoad = () => ({
    type: CORRELATION_LOAD_DATA
});

export const correlationLoadSuccess = (data) => ({
    type: CORRELATION_LOAD_DATA_SUCCESS,
    payload: data
});

export const correlationLoadError = () => ({
    type: CORRELATION_LOAD_DATA_ERROR
});

export const correlationPropertiesUpdate = (data) => ({
    type: CORRELATION_PROPERTIES_UPDATE,
    payload: data
});

export const initialCorrelationTable = (data) => ({
    type: INITIAL_CORRELATION_TABLE,
    payload: data
});

export const updateCorrelationData = (data) => ({
    type: UPDATE_CORRELATION_DATA,
    payload: data
});

export const updateStatusCorrelationLoader = (data) => ({
    type: UPDATE_STATUS_LOADER,
    payload: data
});

export const tableSort = (data) => ({
    type: CUSTOM_SORT,
    payload: data
});
