import { useLocalization } from "@progress/kendo-react-intl";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout"
import { Grid, GridColumn as Column } from "@progress/kendo-react-grid";

import { DateTimeCell, DateTimeCellType, TradeActionCell, PayoutTypeCell, QuantityCell } from "../../../views/DataGridCells";

import {
    TYPE, TOTAL, EQUITY_ACCOUNT, TIME, AMOUNT, QUANTITY, ACTION, AVG_POSITION_PRICE, PRICE, REALIZED_PL, EXECUTIONS, PAYOUTS, UNREALIZED_PL,
    EQUITY_DETAILS_VIEW_POSITION, EQUITY_DETAILS_MARKET_PRICE_AT_BEGIN, EQUITY_DETAILS_MARKET_PRICE_AT_END, EQUITY_DETAILS_AVG_PRICE_AT_END, EQUITY_DETAILS_QTY_AT_END,
} from "../../../../infrastructure/localizationKeys";

const DateCell = (props) => {
    return <DateTimeCell {...props} type={DateTimeCellType.DATE} />
}

const InstrumentDetailsTab = (props) => {
    const localization = useLocalization();

    const InfoPosition = ({ data }) => {
        return (
            <>
                <div className="legend">{localization.toLanguageString(EQUITY_DETAILS_VIEW_POSITION)}</div>
                <GridLayout className="fieldset">
                    <GridLayoutItem row={1} col={1}>
                        {localization.toLanguageString(EQUITY_DETAILS_MARKET_PRICE_AT_BEGIN)} {data.BeginMarketPrice}
                    </GridLayoutItem>
                    <GridLayoutItem row={1} col={2}>
                        {localization.toLanguageString(EQUITY_DETAILS_MARKET_PRICE_AT_END)} {data.EndMarketPrice}
                    </GridLayoutItem>
                    <GridLayoutItem row={1} col={3}>
                        {localization.toLanguageString(EQUITY_DETAILS_AVG_PRICE_AT_END)} {data.EndAveragePrice}
                    </GridLayoutItem>
                    <GridLayoutItem row={1} col={4}>
                        {localization.toLanguageString(EQUITY_DETAILS_QTY_AT_END)} {data.EndQuantity}
                    </GridLayoutItem>
                    <GridLayoutItem row={1} col={5}>
                        {localization.toLanguageString(UNREALIZED_PL)}: {data.UnrealizedPl}
                    </GridLayoutItem>
                </GridLayout>

            </>
        );
    }
    let tabHeight = (Number(props.height.replace("px", '')) + 5) + "px";
    let gridHeight = (props.height.replace("px", '') - 108) + "px";
    
    return (<div style={{ height: tabHeight }} >
        <InfoPosition data={props.instrument} />
        <GridLayout cols={[{ width: "55%" }, { width: "45%" }]} gap={{ rows: 1, cols: 2 }}>
            <GridLayoutItem row={1} col={1} style={{ marginRight : "5px" }}>
                <div className="legend">{localization.toLanguageString(EXECUTIONS)}</div>
                <div className="fieldset-grid">
                    <pre className="legend-table"> {localization.toLanguageString(TOTAL)}: {props.instrument.ExecutionsTotalValue}</pre>
                    <Grid data={props.executions} style={{ height: gridHeight }}>
                        <Column title={localization.toLanguageString(TIME)} field="DateTime" cell={DateCell} />
                        <Column title={localization.toLanguageString(EQUITY_ACCOUNT)} field="Number" />
                        <Column title={localization.toLanguageString(ACTION)} field="TradeAction" cell={TradeActionCell}/>
                        <Column title={localization.toLanguageString(QUANTITY)} cell={QuantityCell} field="Quantity" />
                        <Column title={localization.toLanguageString(PRICE)} field="ExecutionPrice" />
                        <Column title={localization.toLanguageString(AVG_POSITION_PRICE)} field="AveragePositionPrice" width="190px" />
                        <Column title={localization.toLanguageString(REALIZED_PL)} field="RealizedPl" width="145px" />
                    </Grid>
                </div>
            </GridLayoutItem >
            <GridLayoutItem row={1} col={2} style={{ marginLeft: "5px", marginRight: "4px"}}>
                <div className="legend">{localization.toLanguageString(PAYOUTS)}</div>
                <div className="fieldset-grid">
                    <pre className="legend-table"> {localization.toLanguageString(TOTAL)}:  {props.fundMovementTotals[0]?.payoutType}{props.fundMovementTotals[0]?.sum}   {props.fundMovementTotals[1]?.payoutType}{props.fundMovementTotals[1]?.sum}</pre>
                    <Grid data={props.fundMovements} style={{ height: gridHeight }}>
                        <Column title={localization.toLanguageString(TIME)} field="DateTime" cell={DateCell} />
                        <Column title={localization.toLanguageString(EQUITY_ACCOUNT)} field="Number" />
                        <Column title={localization.toLanguageString(TYPE)} field="Type" cell={PayoutTypeCell}/>
                        <Column title={localization.toLanguageString(AMOUNT)} field="ValueString" />
                    </Grid>
                </div>
            </GridLayoutItem >
        </GridLayout>
    </div>
    );
}

export default InstrumentDetailsTab;