import { useDispatch, useSelector } from "react-redux";
import { useLocalization } from "@progress/kendo-react-intl";
import { Tooltip } from "@progress/kendo-react-tooltip";
import { Grid, GridColumn as Column } from "@progress/kendo-react-grid";
import { ToolTipedCell } from "../../views/DataGridCells";
import { DATE_PARTS_YEAR, TOTAL } from "../../../infrastructure/localizationKeys";
import { getPositiveNegativeColor, getBlackWhiteColor } from "../../../infrastructure/gradientPercentToColor";
import ObjectEditor from "../../views/ObjectEditor";
import { payoutsPropertiesUpdate } from "../../../store/reducers/payoutsReducer/creators";

const columns = [
    "january",
    "february",
    "march",
    "april",
    "may",
    "june",
    "july",
    "august",
    "september",
    "october",
    "november",
    "december",
    "total",
]
const uperCaseFirst = (str) => str[0].toUpperCase() + str.slice(1);
const getMonthLocalizationKey = (month) => month === "total" ? TOTAL : `Common_Months_${uperCaseFirst(month)}`;
const TooltipContentTemplate = (props) => <pre>{props.title}</pre>;


const PayoutsTable = (props) => {
    const dispatch = useDispatch();
    const localization = useLocalization();
    const payouts = useSelector(state => state.payouts.data);
    const properties = useSelector(state => state.payouts.properties);
    
    const handleUpdate = (properties) => {
        dispatch(payoutsPropertiesUpdate(properties));
    }
    let tabHeight = (props.height.replace("px", '') - 160) + "px";
    return (

        <div className="payout-bottom-block">
            <ObjectEditor data={properties} onUpdate={handleUpdate} orientation="horizontal" />

            <Tooltip openDelay={100} anchorElement="target" content={TooltipContentTemplate}>
                <Grid data={payouts} style={{ height: tabHeight }} className="payouts-text-size mt-3">
                    <Column
                        field="year"
                        title={localization.toLanguageString(DATE_PARTS_YEAR)}
                        width={60}/>
                    {columns.map((x, i) =>
                        <Column
                            key={x}
                            field={`${x}Data.string`}
                            title={localization.toLanguageString(getMonthLocalizationKey(x))}
                            cell={props => {
                                const offset = props.dataItem[`${x}Data`].cellGradientOffset;
                                const color = getBlackWhiteColor(offset)
                                const background = getPositiveNegativeColor(offset);
                                return (
                                    <ToolTipedCell
                                        {...props}
                                        className="cursor-pointer"
                                        style={{ color, background }}
                                        tooltip={`${x}Data.toolTip`}
                                    />
                                )
                            }}
                        />
                    )}
                </Grid>
            </Tooltip>
        </div>
    )
}

export default PayoutsTable;