import { fork, all, takeEvery } from 'redux-saga/effects';
import { loadUsersSuccess, updateUserSuccess, deleteUserSuccess } from "../../reducers/usersReducer/creators";
import { LOAD_USERS, UPDATE_USER, DELETE_USER } from "../../reducers/usersReducer/actions";
import { requset } from "../infrastructure";

const baseUrl = "/api/users";
const deleteUrl = (id) => `${baseUrl}/${id}`;

// WORKER SAGAS
function* loadUsers() {
    yield requset(baseUrl, "GET", loadUsersSuccess);
}
function* updateUser({ payload }) {
    yield requset(baseUrl, "PUT", updateUserSuccess, null, payload);
}
function* deleteUser({ payload }) {
    yield requset(deleteUrl(payload), "DELETE", deleteUserSuccess);
}

// WATCHER SAGAS
function* updateUserSaga() {
    yield takeEvery(UPDATE_USER, updateUser);
}
function* loadUsersSaga() {
    yield takeEvery(LOAD_USERS, loadUsers);
}
function* deleteUserSaga() {
    yield takeEvery(DELETE_USER, deleteUser);
}

// ROOT SAGA
export default function* usersSaga() {
    yield all([
        fork(loadUsersSaga),
        fork(updateUserSaga),
        fork(deleteUserSaga),
    ]);
}