import { LOCALIZATION_LOAD_DATA_SUCCESS } from "./actions";
const initial = {}

const localizationReducer = (state=initial, action) => {
    switch (action.type) {
        case LOCALIZATION_LOAD_DATA_SUCCESS:
            return {
                ...state,
                data: {
                    default: action.payload.localizationDictionary,
                },
                culture: action.payload.culture,
                loaded: true,
            }
        default:
            return state;
    }
}

export default localizationReducer;