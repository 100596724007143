import { useEffect } from "react";
import { useSelector } from "react-redux";
import { loadMessages, load, IntlProvider } from "@progress/kendo-react-intl";

import { Route, Routes } from 'react-router';

import Private from "../hoc/Private";

import Login from "./Login";
import Register from "./Register";
import Main from "./Main";

import Dashboard from "../pages/Dashboard";
import Equity from "../pages/Equity";
import Portfolio from "../pages/Portfolio";
import Accounts from "../pages/Accounts";

import Users from "../pages/Users";
import User from "../pages/Users/User"
//import Connectors from "../pages/Connectors";
//import Account from "../pages/Accounts/Account";
import Payouts from "../pages/Payouts";
import Ideas from "../pages/Ideas";
import Debug from "../pages/Test";
import Help from "../pages/Help";
import Usage from "../pages/Usage";
import ProfitFixer from "../pages/ProfitFixer";
import RestorePositions from "../pages/RestorePositions";
import OtherInstruments from "../pages/OtherInstruments";
import Correlation from "../pages/Correlation";
import BindDevice from "../pages/BindDevice";
//import StartDemo from "../pages/Demo/index"



import likelySubtags from "cldr-core/supplemental/likelySubtags.json";
import currencyData from "cldr-core/supplemental/currencyData.json";
import weekData from "cldr-core/supplemental/weekData.json";

import numbers from "cldr-numbers-full/main/ru/numbers.json";
import localCurrency from "cldr-numbers-full/main/ru/currencies.json";
import dateFields from "cldr-dates-full/main/ru/dateFields.json";
import caGregorian from "cldr-dates-full/main/ru/ca-gregorian.json";
import timeZoneNames from "cldr-dates-full/main/ru/timeZoneNames.json";

export default function App() {
    const localization = useSelector(state => state.localization);

    useEffect(() => {
        if (localization.loaded) {
            loadMessages(localization.data.default, "default");
            load(weekData)
            load(currencyData)
            load(likelySubtags)
            load(dateFields)
            load(numbers)
            load(caGregorian)
            load(localCurrency)
            load(timeZoneNames)
        }
    })

    

    return (
        <IntlProvider locale={localization.culture}>
            <Routes>
                <Route path='login' element={<Login />}/>
                <Route path='register' element={<Register />}/>

                <Route path='/' element={
                    <Private>
                        <Main />
                    </Private>
                }>
                    <Route path="dashboard" element={<Dashboard />}/>
                    <Route path="equity" element={<Equity />}/>
                    <Route path="portfolio" element={<Portfolio />}/>
                    <Route path="accounts" element={<Accounts />} />
                    <Route path="payouts" element={<Payouts />} />
                    <Route path="profitfixer" element={<ProfitFixer />} />
                    <Route path="restorepositions" element={<RestorePositions />} />
                    <Route path="correlation" element={<Correlation />} />
                    <Route path="otherinstruments" element={<OtherInstruments />} />
                    {/*<Route path="ideas" element={<Ideas />} />*/}
                    <Route path="debug" element={<Debug />} />
                    <Route path="binddevice" element={<BindDevice />} />
                    <Route path="help" element={<Help />} />


                    <Route path="users" element={<Users />} />
                    <Route path="user/:id" element={<User />} />
                    <Route path="usage" element={<Usage />} />

                    <Route path="demo" element={<></>} />

                </Route>
            </Routes>
        </IntlProvider>
    );
}
