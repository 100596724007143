import { put, fork, all, takeEvery, select, delay } from 'redux-saga/effects';
import { HubConnectionBuilder, HttpTransportType } from "@microsoft/signalr";
import { EnumTypes } from "../../../infrastructure/enums/index";
import store from "../../";

// ACTIONS
import { DEMO, SIGN_IN, REGISTER, LOGIN_LOGOUT_SUCCESS, CHECK_VALIDATION_SUCCESS, CHECK_VALIDATION }
    from "../../reducers/loginReducer/actions";
import { LOGOUT }
    from "../../reducers/generalReducer/generalActions";

// CREATORS
import { portfolioUpdateData }
    from "../../reducers/portfolioReducer/creators";
import { newError }
    from "../../reducers/errorsReducer/creators";
import { enumLoad }
    from "../../reducers/enumReducer/creators";
import { login, loginLogutError, loginLogoutSuccess, removeToken, checkValidationSuccess }
    from "../../reducers/loginReducer/creators";
import { dashBoardProgressChange, dasboardTodayeChangeUpdate }
    from "../../reducers/dashboardReducer/creators";
import { profitFixerUpdateCollectionAdd, profitFixerUpdateCollectionRemove, profitFixerOpenExecutionUpdate }
    from "../../reducers/profitFixerReducer/creators";
import { closedExecutionCollectionAdd, closedExecutionCollectionRemove, closedExecutionDataUpdate } from "../../reducers/restorePositionsReducer/creators";

import { initialCorrelationTable, updateCorrelationData, updateStatusCorrelationLoader } from "../../reducers/correlationReducer/creators";

import { requset } from "../infrastructure";

const hubUrl = "/hubs/app-hub";
const baseUrl = "/api/login";
const signInUrl = `${baseUrl}/signin`;
const registerUrl = `${baseUrl}/register`;
const validateUrl = `${baseUrl}/validate`;
const demoUrl = `${baseUrl}/demo`;

// EXTERNAL CALLS
function onDashboardProgressChange(progress) {
    store.dispatch(dashBoardProgressChange({ progress }));
}
function onPortfolioUpdate(args) {
    store.dispatch(portfolioUpdateData(args));
}
function onNewError(error) {
    store.dispatch(newError(error));
}

function onTodayChangeUpdate(value) {
    store.dispatch(dasboardTodayeChangeUpdate(value));
}

function onOpenExecutionCollectionChangeAdd(value) {
    store.dispatch(profitFixerUpdateCollectionAdd(value));
}

function onOpenExecutionCollectionChangeRemove(value) {
    store.dispatch(profitFixerUpdateCollectionRemove(value));
}

function onOpenExecutionUpdate(value) {
    store.dispatch(profitFixerOpenExecutionUpdate(value));
}

function onClosedExecutionCollectionChangeAdd(value) {
    store.dispatch(closedExecutionCollectionAdd(value));
}

function onClosedExecutionCollectionChangeRemove(value) {
    store.dispatch(closedExecutionCollectionRemove(value));
}

function onClosedExecutionUpdate(value) {
    store.dispatch(closedExecutionDataUpdate(value));
}

function onInitialCorrelationTable(value) {
    store.dispatch(initialCorrelationTable(value));
}

function onUpdateCorrelationData(value) {
    store.dispatch(updateCorrelationData(value));
}

function onUpdateStatusCorrelationLoader(value) {
    store.dispatch(updateStatusCorrelationLoader(value));
}

// FUNCTION
function* loadEnums() {
    for (let enumType of EnumTypes) {
        yield put(enumLoad(enumType))
    }
}
function* loginProcess(name, role, token) {
   // todo: remove connection to the middleware
    const connection = new HubConnectionBuilder()
        .withUrl(hubUrl, {
            accessTokenFactory: () => token,
            //transport: HttpTransportType.WebSockets | HttpTransportType.LongPolling
            transport: HttpTransportType.LongPolling
        })
        .build();

    // set all signalr connection here
    connection.on("DashboardProgressChange", onDashboardProgressChange);
    connection.on("PortfolioUpdate", onPortfolioUpdate);
    connection.on("NewError", onNewError);
    connection.on("TodayChangeValue", onTodayChangeUpdate);
    connection.on("OpenExecutionCollectionChangeAdd", onOpenExecutionCollectionChangeAdd);
    connection.on("OpenExecutionCollectionChangeRemove", onOpenExecutionCollectionChangeRemove);
    connection.on("OpenExecutionUpdate", onOpenExecutionUpdate);
    connection.on("ClosedExecutionCollectionChangeAdd", onClosedExecutionCollectionChangeAdd);
    connection.on("ClosedExecutionCollectionChangeRemove", onClosedExecutionCollectionChangeRemove);
    connection.on("ClosedExecutionUpdate", onClosedExecutionUpdate);
    connection.on("InitialCorrelationTable", onInitialCorrelationTable);
    connection.on("UpdateCorrelationData", onUpdateCorrelationData);
    connection.on("UpdateStatusCorrelationLoader", onUpdateStatusCorrelationLoader);
    //

    console.log("SignalR start connecting");

    const connectionPromise = connection.start().then(() => {
        console.log("SignalR connection established")
    }).catch(() => {
        console.log("Can`t established SignalR connection");
    });

    yield connectionPromise;
    yield put(login(token, role, name, connection));
    yield delay(1500);
    yield loadEnums();
}
function* logoutProcess() {
    // todo: remove connection to the middleware
    localStorage.removeItem("token");
    const connection = yield select(state => state.login.connection);
    connection.stop().then(() => {
        console.log("SignalR connection stoped")
    }).catch(() => {
        console.log("SignalR connection CAN`T stoped");
    });
}

// WORKER SAGAS
function* success({ payload }) {
    if (payload.result) {

        if (payload.isProduction)
            ym(90979641, 'reachGoal', payload.isNew ? 'sign_up' : 'sign_in');

        localStorage.setItem("token", payload.token);
        yield loginProcess(payload.name, payload.role, payload.token);

    } else {
        yield put(loginLogutError(payload.message));
    }
};
function* validationSuccess({ payload }) {
    if (payload) {
        const token = localStorage.getItem("token");
        yield loginProcess(payload.name, payload.role, token);
    } else {
        yield put(removeToken());
    }
}
function* signIn({ payload }) {
    yield requset(signInUrl, "POST", loginLogoutSuccess, null, payload, false);
}
function* registration({ payload }) {
    yield requset(registerUrl, "POST", loginLogoutSuccess, null, payload, false);
}
function* demo({ payload }) {
    yield requset(demoUrl, "POST", loginLogoutSuccess, null, payload, false);
}
function* validation() {
    const token = localStorage.getItem("token");
    if (token == null) {
        yield put(removeToken());
        return;
    }
    yield requset(validateUrl, "POST", checkValidationSuccess, removeToken, token, false);
}

// WATCHER SAGAS
function* signInSaga() {
    yield takeEvery(SIGN_IN, signIn);
}
function* registrationSaga() {
    yield takeEvery(REGISTER, registration);
}
function* demoSaga() {
    yield takeEvery(DEMO, demo);
}
function* successSaga() {
    yield takeEvery(LOGIN_LOGOUT_SUCCESS, success);
}
function* validationSaga() {
    yield takeEvery(CHECK_VALIDATION, validation);
}
function* validationSuccessSaga() {
    yield takeEvery(CHECK_VALIDATION_SUCCESS, validationSuccess);
}
function* logoutSaga() {
    yield takeEvery(LOGOUT, logoutProcess);

}



// ROOT SAGA
export default function* loginSaga() {
    yield all([
        fork(signInSaga),
        fork(registrationSaga),
        fork(demoSaga),
        fork(successSaga),

        fork(validationSaga),
        fork(validationSuccessSaga),

        fork(logoutSaga),

        
    ]);
}