import { PAYOUTS_LOAD_DATA, PAYOUTS_LOAD_DATA_SUCCESS, PAYOUTS_LOAD_DATA_ERROR } from "./actions";
import { LOGOUT, CLEAR_ALL, UPDATE_ALL_DATA } from "../generalReducer/generalActions";

const initial = {
    loaded: false,
    loading: false,
    data: [],
    properties: [],
    bondsWithOfferDate: []
}

const payoutsReducer = (state = initial, action) => {
    switch (action.type) {
        case PAYOUTS_LOAD_DATA:
            return {
                ...state,
                loading: true,
            }
        case PAYOUTS_LOAD_DATA_SUCCESS:
            return {
                ...state,
                loaded: true,
                loading: false,
                ...action.payload
            }

        case PAYOUTS_LOAD_DATA_ERROR:
            return {
                ...state,
                loaded: true,
                loading: false,
            }
        case UPDATE_ALL_DATA:
            return {
                ...state,
                loading: false,
                loaded: false
            }
        case CLEAR_ALL:
            return initial;
        case LOGOUT:
            return initial;
        default:
            return state;
    }
}

export default payoutsReducer;