import { ExpansionPanel, ExpansionPanelContent, } from "@progress/kendo-react-layout";
import { useState } from "react";
import { Reveal } from "@progress/kendo-react-animation";
import { useSelector } from "react-redux";
import { useLocalization } from "@progress/kendo-react-intl";

import { PORTFOLIO_PIECES } from "../../../infrastructure/localizationKeys";
const PortfolioTabsMobile = () => {
    const portfolio = useSelector(state => state.portfolio.data);
    const localization = useLocalization();
    
    const [expanded, setExpanded] = useState([]);
    
    const style = {
        marginTop: "10px",
        border: "1px solid darkgrey",
        borderRadius: "5px",
        fontSize: "90%"
    }

    const styleDetails = {
        marginTop: "10px",
        marginLeft: "-10px",
        marginRight: "-10px",
        border: "1px solid darkgrey",
        borderRadius: "5px",
        position: "relative",
        padding: "5px 5px 0px 5px",
        fontSize: "90%"
    }

    const numericFormat = new Intl.NumberFormat("en", { style: "decimal", minimumFractionDigits: 2, maximumFractionDigits: 2 });
    const percentFormat = new Intl.NumberFormat("en", { style: "percent", minimumFractionDigits: 2, maximumFractionDigits: 2 });
    const quantityFormat = new Intl.NumberFormat("en", { style: "decimal", maximumFractionDigits: 2 });

    const getTotal = (data) => {
        return data.totalPl > 0
            ? "+" + numericFormat.format(data.totalPl) + " " + data.instrument.Currency
            : numericFormat.format(data.totalPl) + " " + data.instrument.Currency;
    }

    const getTotalPercent = (data) => {
        return data.totalPlPercent > 0
            ? "+" + percentFormat.format(data.totalPlPercent)
            : percentFormat.format(data.totalPlPercent);
    }

    const getColor = (value) => {
        if (value < 0)
            return "red";
        if (value > 0)
            return "green";
        return "";

    }

    const getSumPosition = (data) => {
        let totalPl = 0;
        let totalPlPercent = 0;
        data.positions.map(x => totalPl += x.totalPl);
        data.positions.map(x => totalPlPercent += x.totalPlPercent);
        let s = totalPl > 0 ? "+" : "";
        let style = { color: getColor(totalPl), fontSize: "90%", textAlign: "right" };
        return <div style={style}>{s}{numericFormat.format(totalPl)} / {s}{percentFormat.format(totalPlPercent)}</div>;
    }

    return <div>
        {portfolio.map(data =>
            <ExpansionPanel
                title={data.name}
                subtitle={getSumPosition(data)}
                expanded={expanded.includes(data.name)}
                tabIndex={0}
                key={data.name}
                style={style}
                onAction={(event) => {
                    setExpanded(event.expanded ? expanded.filter(y => y != data.name) : [...expanded, data.name])
                }}>
                <Reveal>
                    {expanded.includes(data.name) && (
                        <ExpansionPanelContent style={{ marginTop: "-20px" }}>
                            {data.positions.map((data, i) => {
                                const styleMarketValue = {
                                    textAlign: "right",
                                    width: "70%",
                                    paddingBottom: "5px",
                                    color: getColor(data.marketValue)
                                };
                                const styleTotalPl = {
                                    textAlign: "right",
                                    width: "70%",
                                    paddingBottom: "5px",
                                    color: getColor(data.totalPl)
                                };
                                return (<div key={i} style={styleDetails}>
                                    <label style={{ textAlign: "left", width: "30%", paddingBottom: "5px" }}>{data.instrument.Name}</label>
                                    <label style={styleMarketValue}>{numericFormat.format(data.marketValue)} {data.instrument.Currency}</label>
                                    <label style={{ textAlign: "left", width: "30%", paddingBottom: "5px" }}>{quantityFormat.format(data.quantity)} {localization.toLanguageString(PORTFOLIO_PIECES)}</label>
                                    <label style={styleTotalPl}>{getTotal(data)} / {getTotalPercent(data)}</label>
                                </div>)
                            })}
                        </ExpansionPanelContent>)}
                </Reveal>

            </ExpansionPanel>)
        }
    </div >
}

export default PortfolioTabsMobile;
