////import { take, takeEvery, takeLatest, takeLeading, } from 'redux-saga/effects';
////import { apply, call, fork, spawn, join, all } from 'redux-saga/effects';
////import { put } from 'redux-saga/effects';
////import { delay } from 'redux-saga/effects';

import { call, spawn, all } from 'redux-saga/effects';

import loginSaga from "./loginSaga";
import dashboardSaga from "./dashboardSaga";
import usersSaga from "./usersSaga";
import portfolioSaga from "./portfolioSaga";
import accountsSaga from "./accountsSaga";
import updateSaga from "./updateSaga";
import enumsSaga from "./enumsSaga";
import localiztionSaga from "./localiztionSaga";
import payoutsSaga from "./payoutsSaga";
import equitySaga from "./equitySaga";
import ideasSaga from "./ideasSaga";
import debugSaga from "./debugSaga";
import generalSaga from "./generalSaga";
import usageSaga from "./usageSaga";
import profitFixerSaga from "./profitFixerSaga";
import restorePositionsSaga from "./restorePositionsSaga";
import correlationSaga from "./correlationSaga";

export default function* rootSaga() {

    const sagas = [
        localiztionSaga, loginSaga, dashboardSaga, usersSaga, portfolioSaga, accountsSaga,
        updateSaga, enumsSaga, payoutsSaga, equitySaga, ideasSaga, debugSaga, generalSaga,
        usageSaga, profitFixerSaga, restorePositionsSaga, correlationSaga
    ];

    const retrySagas = yield sagas.map(saga => {
        return spawn(function*() {
            while (true) {
                try {
                    yield call(saga);
                    break;
                } catch (e) {
                    console.log(e);
                }
            }
        });
    });

    yield all(retrySagas);
}