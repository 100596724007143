import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useLocalization } from "@progress/kendo-react-intl";
import { ButtonGroup, Button } from "@progress/kendo-react-buttons";
import { loadEquity, equityPropertiesUpdate } from "../../../store/reducers/equityReducer/creators"


import { LOADER, EQUITY_TABLE_HEADER_PERIODS, EQUITY_TABLE_HEADER_INSTRUMENTS, EQUITY_TABLE_HEADER_MONEY } from "../../../infrastructure/localizationKeys";

import EquityProperties from "./EquityProperties";
import ChartMobile from "./ChartMobile";
import PeriodsPanel from "./MobilePanels/PeriodsPanel";
import MoneyPanel from "./MobilePanels/MoneyPanel";
import InstrumentPanel from "./MobilePanels/InstrumentPanel";
import LoaderComponent from "../../views/LoaderComponent";

const Equity = () => {

    const dispatch = useDispatch();
    const localization = useLocalization();
    const loading = useSelector(state => state.equity.loading);
    const loaded = useSelector(state => state.equity.loaded);
    const error = useSelector(state => state.equity.error);
    const properties = useSelector(state => state.equity.properties);
    const loader = useSelector(state => state.equity.loader);

    useEffect(() => {
        if (!loading && !loaded) {
            dispatch(loadEquity());
        }
    })

    
    const updateHandler = (result) => {
        dispatch(equityPropertiesUpdate(result));
    }

    
    const [height, setHeight] = useState(window.innerHeight);
    

    const heightChart = (height / 3.5) + "px";

    if (error)
        return null;

    const resizeWindow = () => {
        setHeight(window.innerHeight);
        
    }

    const buttons = [
        {
            text: localization.toLanguageString(EQUITY_TABLE_HEADER_PERIODS),
            value: "periods"
        },
        {
            text: localization.toLanguageString(EQUITY_TABLE_HEADER_INSTRUMENTS),
            value: "instruments"
        },
        {
            text: localization.toLanguageString(EQUITY_TABLE_HEADER_MONEY),
            value: "money"
        }
    ];

    const [selectButton, setSelectButton] = useState(buttons[0]);

    const handleClick = (value) => {
        const button = buttons.find((m) => m.value === value);
        setSelectButton(button);
    };

    const SelectedComponent = () => {
        switch (selectButton.value) {
            case "periods":
                return <PeriodsPanel />
            case "instruments":
                return <InstrumentPanel />
            case "money":
                return <MoneyPanel />
            default:
                return <div />
        }
    }
    
    return loading || !loaded ?
        (<LoaderComponent description={localization.toLanguageString(LOADER)} />) :
        (<>
            {window.addEventListener('resize', resizeWindow, false)}
            {loader ? <div className="loader-over"> <LoaderComponent description={localization.toLanguageString(LOADER)} /></div> : null}

            <EquityProperties onUpdate={updateHandler} data={properties} />

            <ChartMobile height={heightChart} />

            <div className="equity-chip-list">
                <ButtonGroup>
                    {buttons.map((m, index) => {
                        return (
                            <div key={index}>
                                <Button
                                    className="btn btn-outline-primary btn-sm"
                                    style={{ margin: "5px 2px -10px 2px" }}
                                    togglable={true}
                                    selected={selectButton.value === m.value}
                                    onClick={handleClick.bind(undefined, m.value)}>
                                    {m.text}
                                </Button>
                            </div>
                        );
                    })}
                </ButtonGroup>
            </div>
            <SelectedComponent />
            
        </>);
}

export default Equity;