import { PAYOUTS_PROPERTIES_UPDATE, PAYOUTS_LOAD_DATA, PAYOUTS_LOAD_DATA_SUCCESS, PAYOUTS_LOAD_DATA_ERROR } from "./actions";

export const payoutsLoad = () => ({
    type: PAYOUTS_LOAD_DATA,
})

export const payoutsLoadSuccess = (data) => ({
    type: PAYOUTS_LOAD_DATA_SUCCESS,
    payload: data,
});

export const payoutsLoadError = () => ({
    type: PAYOUTS_LOAD_DATA_ERROR,
});

export const payoutsPropertiesUpdate = (data) => ({
    type: PAYOUTS_PROPERTIES_UPDATE,
    payload: data
})