import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useLocalization } from "@progress/kendo-react-intl";
import { Grid, GridColumn as Column } from "@progress/kendo-react-grid";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import ProgressComponent from "../../views/ProgressComponent";
import { Button } from '@progress/kendo-react-buttons';
import { Upload } from "@progress/kendo-react-upload";
import { Dialog } from "@progress/kendo-react-dialogs";

import {
    ACCOUNT, BROKER, HOLDER, LOADER, ACCOUNT_IMPORT_STATEMENT, ACCOUNT_CLEAR, DASHBOARD_IMPORT_STATEMENT_HEADER,
    CONFIRM, YES, NO, ACCOUNT_YOU_SURE_DELETE_ACCOUNTS, LOAD_STATEMENT_HELP
} from "../../../infrastructure/localizationKeys";

import { BrokerCell } from "../../views/DataGridCells";

import { loadAccounts, clearAllData } from "../../../store/reducers/accountsReducer/creators";
import { updateAllData } from "../../../store/reducers/generalReducer/generalCreators";
import LoaderComponent from "../../views/LoaderComponent";
import Account from "./Account/index.jsx"
const saveUrl = "/api/dashboard/upload";

function AccountD() {
    const localization = useLocalization();
    const dispatch = useDispatch();
    const [visibleModal, setVisibleModal] = useState(false);
    const [visibleModalDelete, setVisibleModalDelete] = useState(false);

    const accounts = useSelector(state => state.accounts);

    const { data, loading, loaded, progress, error, processing } = accounts;

    const token = useSelector(state => state.login.token);
    const saveHeaders = { Authorization: "Bearer " + token }

    const [selected, setSelected] = useState(0);

    const handleClick = (event) => {
        setSelected(event.dataItem["id"]);
    }

    const toggleDialog = () => {
        setVisibleModal(!visibleModal);
    };

    const toggleDialogDelete = () => {
        setVisibleModalDelete(!visibleModalDelete);
    };

    useEffect(() => {
        if (!loading && !loaded)
            dispatch(loadAccounts());
    })

    const DialogModal = () => {

        const UploadComponent = (
            <>
                <div className="link-instruction-report">
                    <a href="https://investortools.ru/help/statements" target="_blank">{localization.toLanguageString(LOAD_STATEMENT_HELP)}</a>
                </div>
                <Upload
                    disabled={processing}
                    accept=".csv,application/vnd.ms-excel,.xml,.html"
                    autoUpload={false}
                    multiple={true}
                    batch={true}
                    onStatusChange={toggleDialog}
                    actionsLayout="start"
                    defaultFiles={[]}
                    saveUrl={saveUrl}
                    saveHeaders={saveHeaders}
                />
            </>
        );
        return (
            visibleModal &&
            <Dialog title={localization.toLanguageString(DASHBOARD_IMPORT_STATEMENT_HEADER)} modal={true} onClose={toggleDialog} resizable={true} width={550}>
                {UploadComponent}
            </Dialog>

        );
    }

    const clearAll = () => {
        setVisibleModalDelete(!visibleModalDelete);
        setSelected(0);
        dispatch(clearAllData());

    }

    const DialogModalDelete = () => {
        return (
            visibleModalDelete &&
            <Dialog title={localization.toLanguageString(CONFIRM)} modal={true} onClose={toggleDialogDelete} resizable={true} width={550}>
                <h5>{localization.toLanguageString(ACCOUNT_YOU_SURE_DELETE_ACCOUNTS)}</h5>
                <Button onClick={toggleDialogDelete} className="btn-dialog">
                    {localization.toLanguageString(NO)}
                </Button>
                <Button onClick={clearAll} className="btn-dialog">
                    {localization.toLanguageString(YES)}
                </Button>
            </Dialog>
        );
    }

    let accountTableColumn = progress ? "account-table-column-min cursor-pointer" : "account-table-column cursor-pointer";

    if (progress != undefined)
        if (!progress.isProcessing && progress.currentStep === progress.stepsCount)
            dispatch(updateAllData());


    return (
        <>
            {loading ? (<LoaderComponent description={localization.toLanguageString(LOADER)} />) : (
                <>
                    <div>
                        <Button className="accounts-import-report" onClick={toggleDialog} disabled={progress}>
                            {localization.toLanguageString(ACCOUNT_IMPORT_STATEMENT)}
                        </Button>
                        <Button className="accounts-clear" onClick={toggleDialogDelete} disabled={progress}>
                            {localization.toLanguageString(ACCOUNT_CLEAR)}
                        </Button>
                        {progress &&
                            <div className="account-progress">
                                <ProgressComponent progress={progress} error={error} />
                            </div>}
                        <DialogModal />
                        <DialogModalDelete />
                    </div >
                    < GridLayout align={{ vertical: "stretch" }}
                        cols={[
                            {
                                width: "40%",
                            },
                            {
                                width: "60%",
                            }
                        ]}

                        gap={{
                            rows: 1,
                            cols: 2,
                        }}>
                        <GridLayoutItem row={1} col={1}>
                            <Grid
                                className={accountTableColumn}
                                data={data}
                                onRowClick={handleClick}>
                                <Column field="number" title={localization.toLanguageString(ACCOUNT)} />
                                <Column field="broker" title={localization.toLanguageString(BROKER)} cell={BrokerCell} />
                                <Column field="holder" title={localization.toLanguageString(HOLDER)} />
                            </Grid>
                        </GridLayoutItem>
                        <GridLayoutItem row={1} col={2} className={accountTableColumn}>
                            {selected != 0 && <Account data={selected} />}
                        </GridLayoutItem>
                    </GridLayout >
                </>
            )}
        </>
    );
}

export default AccountD;