import { useSelector } from "react-redux";
import { useState } from "react";
import { useLocalization } from "@progress/kendo-react-intl";
import { ExpansionPanel, ExpansionPanelContent, } from "@progress/kendo-react-layout";
import { Reveal } from "@progress/kendo-react-animation";

const PeriodsPanel = () => {
    const periods = useSelector(state => state.equity.periods);
    const localization = useLocalization();

    const columns = [
        "january",
        "february",
        "march",
        "april",
        "may",
        "june",
        "july",
        "august",
        "september",
        "october",
        "november",
        "december"
    ]

    const uperCaseFirst = (str) => str[0].toUpperCase() + str.slice(1);
    const getMonthLocalizationKey = (month) => month === "total" ? TOTAL : `Common_Months_${uperCaseFirst(month)}`;

    const [expanded, setExpanded] = useState([]);

    const getMonthValue = (props) => {
        return props;
    }

    const style = {
        marginTop: "10px",
        border: "1px solid darkgrey",
        borderRadius: "5px",
        position: "relative",
        fontSize: "90%"
    }

    const getColor = (value) => {
        if (value < 0)
            return "red";
        if (value > 0)
            return "green"
        return "";
    }

    const getTotal = (data) => {
        const style = {
            color: getColor(data.totalValue)
        }
        return <div style={style}>{data.totalString}</div>
    }

    return (periods.length > 0 ?
        periods.map(data =>
            <ExpansionPanel
                title={data.year}
                subtitle={getTotal(data)}
                expanded={expanded.includes(data.year)}
                tabIndex={0}
                key={data.year}
                style={style}
                onAction={(event) => {
                    setExpanded(event.expanded ? expanded.filter(y => y != data.year) : [...expanded, data.year]);
                }}>
                <Reveal>
                    {expanded.includes(data.year) && (
                        <ExpansionPanelContent>
                            {columns.map(x => {
                                const valueString = getMonthValue(data[`${x}String`]);
                                return (
                                    valueString === ""
                                        ? null
                                        : <div key={x}>
                                            <label style={{ textAlign: "left", width: "50%", paddingBottom: "5px" }}>{localization.toLanguageString(getMonthLocalizationKey(x))}</label>
                                            <label style={{ textAlign: "right", width: "50%", paddingBottom: "5px", color: getColor(getMonthValue(data[`${x}Value`])) }}>{valueString}</label>
                                        </div>);
                            }
                            )}
                        </ExpansionPanelContent>
                    )}
                </Reveal>
            </ExpansionPanel>
        ) : null
    );
}

export default PeriodsPanel;