import { useLocation, Navigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import LoaderComponent from "../views/LoaderComponent";

function Private({ children }) {
    const from = useLocation().pathname;
    const loginStatus = useSelector(state => state.login);
    const dispatch = useDispatch();

    const { authorize, storageChecked, storageChecking, role } = loginStatus;

    console.log("private form check");
    console.log("from", from);
    console.log("authorize", authorize);

    if (authorize) {
        localStorage.setItem("path", from);
        if (role === "Admin") {
            if (from !== "/users" && from !== "/usage" && !from.startsWith("/user")) {
                return <Navigate to="/users" />
            }
        } else {
            if (from === "/users" || from === "/usage" || from.startsWith("/user")) {
                return <Navigate to="/dashboard" />
            }
        }
        return children;
    }

    console.log("storage checked", storageChecked);

    if (from === "/demo") {
        return (<Navigate to="login" state={{ demo: true }} />);
    }

    if (storageChecked) {
        return (<Navigate to="login" state={{ from }} />);
    }

    console.log("storage checking", storageChecking);
    const savePath = localStorage.getItem("path");
    console.log("saved path", savePath);


    if (!storageChecking) {
        dispatch({
            type: "CHECK_VALIDATION"
        });
        return <Navigate to={savePath || from} />
    }

    return (
        <div className="page">
            <LoaderComponent description="Loading..." />
        </div>
    )
}

export default Private;