import {
    LOAD_USER_USAGE_SUCCESS,
    LOAD_FUNC_USAGE_SUCCESS,
    LOAD_USAGE, LOAD_USAGE_SUCCESS,
} from "./actions";

import { LOGOUT } from "../generalReducer/generalActions";

const initial = {
    loading: false,
    loaded: false,
    usage: [],
    funcUsage: [],
    userUsage: {},
};

function usageReducer(state = initial, action) {
    switch (action.type) {

        // LOAD USAGE
        case LOAD_USAGE:
            return {
                ...state,
                loading: true,
            }
        case LOAD_USAGE_SUCCESS:
            return {
                ...state,
                loading: false,
                loaded: true,
                usage: action.payload,
            }

        // LOAD USER USAGE
        case LOAD_USER_USAGE_SUCCESS:
            return {
                ...state,
                userUsage: action.payload,
            }

        // LOAD FUNC USAGE
        case LOAD_FUNC_USAGE_SUCCESS:
            return {
                ...state,
                funcUsage: action.payload,
            }
        
        // GENERAL
        case LOGOUT:
            return initial;
        default:
            return state;
    }
}

export default usageReducer;