import {
    RESTORE_POSITIONS_LOAD_DATA,
    RESTORE_POSITIONS_PROPERTIES_UPDATE,
    RESTORE_POSITIONS_ERROR,
    RESTORE_POSITIONS_LOAD_DATA_SUCCESS,
    RESTORE_POSITIONS_UPDATE_COLLECTION_ADD,
    RESTORE_POSITIONS_UPDATE_COLLECTION_REMOVE,
    RESTORE_POSITIONS_UPDATE_EXECUTION
} from "./actions";

export const restorePostionsLoad = () => ({
    type: RESTORE_POSITIONS_LOAD_DATA
});

export const restorePositionsLoadSuccess = (data) => ({
    type: RESTORE_POSITIONS_LOAD_DATA_SUCCESS,
    payload: data
});

export const restorePositionsPropertiesUpdate = (data) => ({
    type: RESTORE_POSITIONS_PROPERTIES_UPDATE,
    payload: data
});

export const restorePositionsLoadError = () => ({
    type: RESTORE_POSITIONS_ERROR
});

export const closedExecutionCollectionAdd = (data) => ({
    type: RESTORE_POSITIONS_UPDATE_COLLECTION_ADD,
    payload: data
});

export const closedExecutionCollectionRemove = (data) => ({
    type: RESTORE_POSITIONS_UPDATE_COLLECTION_REMOVE,
    payload: data
});

export const closedExecutionDataUpdate = (data) => ({
    type: RESTORE_POSITIONS_UPDATE_EXECUTION,
    payload: data
});