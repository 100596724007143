import {
    ACCOUNTS_LOAD_DATA, ACCOUNTS_LOAD_DATA_ERROR, ACCOUNTS_LOAD_DATA_SUCCESS,
    /*ACCOUNTS_CREATE_CONNECTOR_SUCCESS, ACCOUNTS_REMOVE_CONNECTOR_SUCCESS, ACCOUNTS_UPDATE_CONNECTOR_SUCCESS,
    ACCOUNTS_CONNECTOR_CONNECT, ACCOUNTS_CONNECTOR_DISCONNECT, ACCOUNTS_CONNECTOR_STATUS_CHANGE*/
} from "./actions"

import {DASHBOARD_PROGRESS_CHANGE} from "../dashboardReducer/actions"

import { LOGOUT, CLEAR_ALL, UPDATE_ALL_DATA} from "../generalReducer/generalActions";

const initial = {
    loading: false,
    loaded: false,
    loadingStatements: false,
    loadedStatements: false,
    data: [],
    progress: undefined,
    error: false,
    processing: false,
}

const accountsReducer = (state = initial, action) =>
{
    switch (action.type) {
        // ACCOUNTS
        case ACCOUNTS_LOAD_DATA:
            return {
                ...state,
                loading: true,
                loaded: false,
            }
        case ACCOUNTS_LOAD_DATA_SUCCESS:
            return {
                ...state,
                loading: false,
                loaded: true,
                data: action.payload.data,
                progress: undefined
            }
        case ACCOUNTS_LOAD_DATA_ERROR:
            return {
                ...state,
                loading: false,
                loaded: true,
            }
        // CONNECTORS
        //case ACCOUNTS_CREATE_CONNECTOR_SUCCESS:
        //    return {
        //        ...state,
        //        data: state.data.map(x => x.id === action.payload.data.id ? {
        //            ...x,
        //            connector: action.payload.data.connector
        //        } : {
        //            ...x,
        //        })
        //    }
        //case ACCOUNTS_REMOVE_CONNECTOR_SUCCESS:
        //    return {
        //        ...state,
        //        data: state.data.map(x => x.id === action.payload.data.id ? {
        //            ...x,
        //            connector: null
        //        } : {
        //            ...x,
        //        })
        //    }

        //case ACCOUNTS_UPDATE_CONNECTOR_SUCCESS:
        //    return {
        //        ...state,
        //        data: state.data.map(x => x.id === action.payload.data.id ? {
        //            ...x,
        //            connector: action.payload.data.connector
        //        } : {
        //            ...x,
        //        })
        //    }

        //// CONNECTOR CONNECT
        //case ACCOUNTS_CONNECTOR_CONNECT:
        //    return {
        //        ...state,
        //        data: state.data.map(x => x.id === action.payload.data ? {
        //            ...x,
        //            connector: {
        //                ...x.connector,
        //                processing: true,
        //                connectionStatus: "Connecting"
        //            }
        //        } : {
        //            ...x,
        //        })
        //    }

        //// CONNECTOR DISCONNECT
        //case ACCOUNTS_CONNECTOR_DISCONNECT:
        //    return {
        //        ...state,
        //        data: state.data.map(x => x.id === action.payload.data ? {
        //            ...x,
        //            connector: {
        //                ...x.connector,
        //                processing: true,
        //                connectionStatus: "Connecting"
        //            }
        //        } : {
        //            ...x,
        //        })
        //    }
        //// CONNECTOR STATUS CHANGE
        //case ACCOUNTS_CONNECTOR_STATUS_CHANGE:
        //    return {
        //        ...state,
        //        data: state.data.map(x => x.id === action.payload.data.id ? {
        //            ...x,
        //            connector: {
        //                ...x.connector,
        //                processing: false,
        //                connected: action.payload.data.connected,
        //                connectionStatus: action.payload.data.connectionStatus,
        //            }
        //        } : {
        //            ...x,
        //        })
        //    }
        case DASHBOARD_PROGRESS_CHANGE:
            return {
                ...state,
                ...action.payload
            }
        case UPDATE_ALL_DATA:
            return {
                ...state,
                loading: false,
                loaded: false,
                progress: undefined,
            }
        case CLEAR_ALL:
            return initial;
        case LOGOUT:
            return initial;
        default:
            return state;
    }
}

export default accountsReducer;