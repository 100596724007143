import {
    IDEAS_LOAD_DATA, IDEAS_LOAD_DATA_SUCCESS, IDEAS_LOAD_DATA_ERROR,
    ADD_IDEA, ADD_IDEA_SUCCESS, 
    IDEA_UPDATE, IDEA_UPDATE_SUCCESS,
    IDEA_ADD_NOTE, IDEA_ADD_NOTE_SUCCESS,
    UPDATE_NOTE, UPDATE_NOTE_SUCCESS,
    DEACTIVATE_IDEA, DEACTIVATE_IDEA_SUCCESS,

    SELECT_IDEA, SELECT_NOTE,
    CHANGE_SYMBOL, CHANGE_NOTE
} from "./actions";

// LOAD IDEAS
export const loadIdeas = (data) => ({
    type: IDEAS_LOAD_DATA,
});
export const loadIdeasSuccess = (data) => ({
    type: IDEAS_LOAD_DATA_SUCCESS,
    payload: data,
});
export const loadIdeasError = (data) => ({
    type: IDEAS_LOAD_DATA_ERROR,
    payload: data,
});

// ADD IDEA
export const addIdea = (data) => ({
    type: ADD_IDEA,
    payload: data,
});
export const addIdeaSuccess = (data) => ({
    type: ADD_IDEA_SUCCESS,
    payload: data,
});

// DEACTIVATE IDEA
export const deactivateIdea = (data) => ({
    type: DEACTIVATE_IDEA,
    payload: data,
});

export const deactivateIdeaSuccess = (data) => ({
    type: DEACTIVATE_IDEA_SUCCESS,
    payload: data,
});

 //UPDATE IDEA
export const updateIdea = (idIdea, parameters) => ({
    type: IDEA_UPDATE,
    payload: {
        idIdea,
        parameters
    }
});
export const updateIdeaSuccess = (data) => ({
    type: IDEA_UPDATE_SUCCESS,
    payload: data,
});

// ADD NOTE
export const addNote = (idIdea, text) => ({
    type: IDEA_ADD_NOTE,
    payload: {
        idIdea,
        text,
    }
});
export const addNoteSuccess = (data) => ({
    type: IDEA_ADD_NOTE_SUCCESS,
    payload: data,
});

// UPDATE NOTE
export const updateNote = (idIdea, idNote, text) => ({
    type: UPDATE_NOTE,
    payload: {
        idIdea,
        idNote,
        text,
    }
});
export const updateNoteSuccess = (data) => ({
    type: UPDATE_NOTE_SUCCESS,
    payload: data,
});

// LOCAL ACTIONS
export const changeSymbol = (data) => ({
    type: CHANGE_SYMBOL,
    payload: data,
});
export const changeNote = (data) => ({
    type: CHANGE_NOTE,
    payload: data,
});
export const selectIdea = (data) => ({
    type: SELECT_IDEA,
    payload: data,
});
export const selectNote = (data) => ({
    type: SELECT_NOTE,
    payload: data,
});