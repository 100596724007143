import { useSelector } from "react-redux";
import { useState } from "react";
import { useLocalization } from "@progress/kendo-react-intl";
import { ExpansionPanel, ExpansionPanelContent, } from "@progress/kendo-react-layout";
import { Reveal } from "@progress/kendo-react-animation";

import { SYMBOL, REALIZED_PL, UNREALIZED_PL, EQUITY_TABLE_COLUMN_DIVIDENS_AND_COUPONS } from "../../../../infrastructure/localizationKeys";

const InstrumentPanel = () => {
    const instruments = useSelector(state => state.equity.instruments);
    const localization = useLocalization();

    const [expanded, setExpanded] = useState([]);

    const style = {
        marginTop: "10px",
        fontSize: "90%",
        border: "1px solid darkgrey",
        borderRadius: "5px",
        position: "relative"
    }

    const getColor = (value) => {
        if (value < 0)
            return "red";
        if (value > 0)
            return "green"
        return "";
    }

    const getTotal = (data) => {
        const style = {
            color: getColor(data.TotalCellGradientOffset)
        }
        return <div style={style}>{data.TotalString}</div>
    }

    return (instruments.length > 0
        ? instruments.map(data =>
            <ExpansionPanel
                title={data.ShortName}
                subtitle={getTotal(data)}
                expanded={expanded.includes(data.ShortName)}
                tabIndex={0}
                key={data.ShortName}
                style={style}
                onAction={(event) => {
                    setExpanded(event.expanded ? expanded.filter(y => y != data.ShortName) : [...expanded, data.ShortName]);
                }}>
                <Reveal>
                    {expanded.includes(data.ShortName) && (
                        <ExpansionPanelContent>
                            <div>
                                <label style={{ textAlign: "left", width: "60%", paddingBottom: "5px" }}>{localization.toLanguageString(SYMBOL)}</label>
                                <label style={{ textAlign: "right", width: "40%", paddingBottom: "5px" }}>{data.Symbol}</label>
                                <label style={{ textAlign: "left", width: "60%", paddingBottom: "5px" }}>{localization.toLanguageString(REALIZED_PL)}</label>
                                <label style={{
                                    textAlign: "right", width: "40%", paddingBottom: "5px", color: getColor(data.RealizedPlCellGradientOffset) }}>{data.RealizedPlString}</label>
                                <label style={{ textAlign: "left", width: "60%", paddingBottom: "5px" }}>{localization.toLanguageString(UNREALIZED_PL)}</label>
                                <label style={{
                                    textAlign: "right", width: "40%", paddingBottom: "5px", color: getColor(data.UnrealizedPlCellGradientOffset) }}>{data.UnrealizedPlString}</label>
                                <label style={{ textAlign: "left", width: "60%", paddingBottom: "5px" }}>{localization.toLanguageString(EQUITY_TABLE_COLUMN_DIVIDENS_AND_COUPONS)}</label>
                                <label style={{ textAlign: "right", width: "40%", paddingBottom: "5px" }}>{data.DividendsAndCouponsString}</label>
                            </div>
                        </ExpansionPanelContent>
                    )}
                </Reveal>
            </ExpansionPanel>
        )
        : null
    );
}

export default InstrumentPanel;