import { useSelector } from "react-redux";

import { Grid, GridColumn as Column } from "@progress/kendo-react-grid";

import { DateTimeCell } from "../../views/DataGridCells";

const FuncUsageGrid = () => {
    const data = useSelector(state => state.usage.funcUsage);

    return (
        <Grid data={data} className="admin-table">
            <Column field="date" title="Date" cell={DateTimeCell}/>
            <Column field="user.name" title="User" />
            <Column field="arguments" title="Arguments"/>
        </Grid>
    )
}

export default FuncUsageGrid;