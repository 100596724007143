import { put, fork, all, takeEvery, delay } from 'redux-saga/effects';
import { CHANGE_EXPAND_MENU_SAGA, CHANGE_EXPAND_MENU } from "../../reducers/generalReducer/generalActions";

// FUNCTION

function* changeExpand() {
    yield delay(100);
    yield put({
        type: CHANGE_EXPAND_MENU_SAGA
    })
}

//WATCHER SAGAS

function* changeExpandSaga() {
    yield takeEvery(CHANGE_EXPAND_MENU, changeExpand);
}

//ROOT SAGA

export default function* generalSaga() {
    yield all([
            fork(changeExpandSaga),
        ]);
}