import { fork, all, takeEvery } from 'redux-saga/effects';
import { ENUM_LOAD_DATA } from "../../reducers/enumReducer/actions";
import { enumLoadSuccess } from "../../reducers/enumReducer/creators";
import { requset } from "../infrastructure";

const baseUrl = (name) => `/api/enums/${name}`;

// WORKER SAGAS
function* getEnum({ payload }) {
    yield requset(baseUrl(payload), "GET", enumLoadSuccess);
}

// WATCHER SAGAS
function* getEnumSaga() {
    yield takeEvery(ENUM_LOAD_DATA, getEnum);
}

// ROOT SAGA
export default function* enumSaga() {
    yield all([
        fork(getEnumSaga),
    ]);
}