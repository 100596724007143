import { isMobile } from 'react-device-detect';

import AccountD from "./AccountD";
import AccountM from "./AccountM";


const Accounts = () => {
    return (
        isMobile ? <AccountM /> : <AccountD />
    );
}

export default Accounts;