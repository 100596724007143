import { ProgressBar } from '@progress/kendo-react-progressbars';
import { Hint } from "@progress/kendo-react-labels";

const ProgressComponent = ({ progress, error }) => {
    return <>
        <ProgressBar
            className="w-100 mt-3"
            max={progress.stepsCount}
            value={progress.currentStep}
            label={() => <strong>{progress.currentStep}/{progress.stepsCount}</strong>}
            ariaDescribedBy={"progressHint"}
        />
        <Hint id={"progressHint"} className={error ? "text-danger" : ""}>
            {progress.message}
        </Hint>
    </>
}

export default ProgressComponent;