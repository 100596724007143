import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { useLocalization } from "@progress/kendo-react-intl";

import { profitFixerLoad } from "../../../store/reducers/profitFixerReducer/creators";
import { LOADER } from "../../../infrastructure/localizationKeys";
import LoaderComponent from "../../views/LoaderComponent";
import OpenExecutionsTable from "./OpenExecutionsTable";
const ProfitFixerD = () => {

    const dispatch = useDispatch();
    const localization = useLocalization();

    const loading = useSelector(state => state.profitFixer.loading);
    const loaded = useSelector(state => state.profitFixer.loaded);

    useEffect(() => {
        if (!loading && !loaded) {
            dispatch(profitFixerLoad());
        }
    })

    return (
        loading
            ? <LoaderComponent description={localization.toLanguageString(LOADER)} />
            : <OpenExecutionsTable />
    )
}

export default ProfitFixerD;