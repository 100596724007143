import { useSelector } from "react-redux";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { DatePicker } from "@progress/kendo-react-dateinputs";

const CorrelationProperties = (props) => {
    const data = props.data;
    
    const onPropertyEdit = (name, value) => {
        const result = data.map(x => x.propertyName === name ? { ...x, value } : { ...x });
        props.onUpdate(result);
    }

    const styleLine = {
        display: "inline-block",
        width: "380px"
    }

    const styleLabel = {
        width: "25px",
        fontSize: "90%"
    }

    return (
        <div className="equity-properties">
            <div style={styleLine}>
                <label style={styleLabel}>{data[0]?.header}</label>
                <DateTimeField {...data[0]}
                    key={data[0]?.propertyName}
                    onPropertyEdit={onPropertyEdit}
                    width={100}
                    />
                <b style={{ marginLeft: "5px" }}></b>
                <label style={styleLabel}>{data[1]?.header}</label>
                <DateTimeField {...data[1]}
                    key={data[1]?.propertyName}
                    onPropertyEdit={onPropertyEdit}
                    width={100}
                />
                <b style={{ marginLeft: "5px" }}></b>
                <EnumField {...data[2]}
                    key={data[2]?.propertyName}
                    onPropertyEdit={onPropertyEdit}
                    width={106}
                    />
                    
            </div>
        </div>
    )
}


const DateTimeField = (props) => {

    const handleChange = (event) => {
        props.onPropertyEdit(props.propertyName, event.target.value.toISOString());
    }

   

    return (
            <DatePicker
                defaultValue={new Date(props.value)}
                format="dd.MM.yy"
                onChange={handleChange}
                size="small"
                width={props.width}
            />
    );
}

const EnumField = (props) => {
    const data = useSelector(state => state.enums[props.propertyType]);
    const display = data?.find(x => x.key === props.value).display;
    const value = { key: props.value, display };

    const handleChange = (event) => {
        props.onPropertyEdit(props.propertyName, event.target.value.key);
    }

    const style = {
        width: props.width,
        fontSize: "90%",
        height: "35px"
    }

    return (
        <DropDownList
            style={style}
            disabled={props.disabled}
            onChange={handleChange}
            textField="display"
            dataItemKey="key"
            data={data}
            value={value}
        />
    )
}

export default CorrelationProperties;