import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { useLocalization } from "@progress/kendo-react-intl";

import { restorePostionsLoad, restorePositionsPropertiesUpdate } from "../../../store/reducers/restorePositionsReducer/creators";
import { LOADER } from "../../../infrastructure/localizationKeys";
import LoaderComponent from "../../views/LoaderComponent";
import ObjectEditor from "../../views/ObjectEditor";
import MobilePanelLastExecutions from "./MobilePanelLastExecutions";

const RestorePositionsM = () => {
    const dispatch = useDispatch();
    const localization = useLocalization();

    const loaded = useSelector(state => state.restorePositions.loaded);
    const loading = useSelector(state => state.restorePositions.loading);
    const properties = useSelector(state => state.restorePositions.properties);

    useEffect(() => {
        if (!loaded && !loading)
            dispatch(restorePostionsLoad());
    });

    const handleUpdate = (properties) => {
        dispatch(restorePositionsPropertiesUpdate(properties))
    }

    return (loading ?
        <LoaderComponent description={localization.toLanguageString(LOADER)} />
        :
        <>
            <ObjectEditor data={properties} onUpdate={handleUpdate} orientation="horizontal" />
            <MobilePanelLastExecutions/>
        </>
    );
}

export default RestorePositionsM;