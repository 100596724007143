

const Help = () => {

    return (
        <div className="help-page">
            <div className="text-help">
                <p  style={{ paddingTop: "10px" }}>Представляем вашему вниманию тестовую версию "Инструментов Инвестора".</p>
                Которая содержит минимально полезный функционал и позволяет: <br />
                - Построить консолидированный портфель из нескольких брокерских счетов. <br />
                - Оценить его диверсификацию по различным характеристикам. <br />
                - Подробно изучить историю портфеля, выявить сильные и слабые стороны. <br />
                - Сравнить его с индексом Мосбиржи и инфляцией. <br />
                - Просмотреть выплаты купонов и дивидендов. Спланировать фиксированный доход на будущее. <br />
                - Подобрать облигации по заданным параметрам, рассчитать их реальную доходность (с учетом комиссий и налогов) в реальном времени. <br />
                - Автоматически разместить ордера на приобретение выбранных инструментов и задать правила набора позиции. <br />
                <br />
                <br />
                <b>Немного о нас.</b> <br />
                На протяжении последних 7 лет, мы занимаемся разработкой программного обеспечения в области биржевой торговли и инвестиций. <br />
                Сами инвестируем используя свои разработки. <br />
                Данный продукт это попытка объединения различных наработок в одном приложении и создания полезного инвесторам инструмента. <br />
                <br />
                <br />
                <b>Целью выпуска</b> данной версии является получение обратней связи с потенциальными пользователями. <br />
                Сверка направления развития и выявления потребностей. <br />
                Мы осознаем что на данном этапе продукт слабо документирован и какой-то функционал не очевиден и готовы помогать в его освоении. <br />
                Будем рады любому общению. <br />
                Пишите в <a href="https://t.me/ToolsForInvestors" target="_blank">Telegram</a><br />
                <br />
                <br />
                <b>Ближайшие планы</b> <br />
                Тот факт, что мы самостоятельно используем продукт, служит уверенностью что мы продолжим развивать его в будущем. <br />
                Огромное количество наработок еще не перенесено в него, с кратким списком основных планов можно ознакомится на  <a href="https://investortools.ru/#plan" target="_blank">нашем сайте.</a> <br />
                Однако по результатом публичного тестирования будет внесены коррективы и расставлены приоритеты. <br />
            </div>
        </div>
    );

}

export default Help;