import { useLocalization } from "@progress/kendo-react-intl";
import { Grid, GridColumn as Column } from "@progress/kendo-react-grid";
import { DateTimeCell, DateTimeCellType } from "../../../views/DataGridCells";
import { ACCOUNT_HEADER_FROM, ACCOUNT_HEADER_TO, ACCOUNT_HEADER_SOURCE } from "../../../../infrastructure/localizationKeys";


const DateCell = (props) => <DateTimeCell {...props} type={DateTimeCellType.DATE} />

const StatementsTable = ({ data }) => {
    const localization = useLocalization();
    return (
        <Grid data={data}>
            <Column title={localization.toLanguageString(ACCOUNT_HEADER_FROM)} field="StartDateTime" cell={DateCell} />
            <Column title={localization.toLanguageString(ACCOUNT_HEADER_TO)} field="EndDateTime" cell={DateCell} />
            <Column title={localization.toLanguageString(ACCOUNT_HEADER_SOURCE)} field="Source" />
        </Grid>
    )
};

export default StatementsTable;