import { useDispatch, useSelector } from "react-redux";

import { useLocalization } from "@progress/kendo-react-intl";
import { Notification, NotificationGroup } from "@progress/kendo-react-notification";
import { Slide } from "@progress/kendo-react-animation";
import { removeError } from "../../../store/reducers/errorsReducer/creators";

import {
    ERRORS_SERVICE_TOTAL_ERRORS_1,
    ERRORS_SERVICE_TOTAL_ERRORS_2,
    ERRORS_SERVICE_TOTAL_ERRORS_3,
} from "../../../infrastructure/localizationKeys";

import { getDeclension } from "../../../infrastructure/extensions"

const ErrorNotification = (props) => {
    const localization = useLocalization();
    const dispatch = useDispatch();
    const errors = useSelector(state => state.errors.data);
    const remove = () => dispatch(removeError());
    const errorCount = errors.length;
    const error = errorCount ? errors[0] : null;

    const declensions = [
        localization.toLanguageString(ERRORS_SERVICE_TOTAL_ERRORS_1),
        localization.toLanguageString(ERRORS_SERVICE_TOTAL_ERRORS_2),
        localization.toLanguageString(ERRORS_SERVICE_TOTAL_ERRORS_3)
    ];

    const type = {
        style: "warning",
        icon: true,
    };

    const groupStyle = {
        bottom: 15,
        left: 18,
        zIndex: 1,
        fontWeight: "bold",
    };

    const style = (side) => ({
        width: "50%",
        textAlign: side,
        float: side,
    });

    return (
        <NotificationGroup style={groupStyle}>
            <Slide direction={error ? "up" : "down"}>
                {error && (
                <Notification type={type} closable={true} onClose={remove}>
                    <div className="content-in-page">
                        <div style={style("left")}>
                            {error}
                        </div>
                        {errorCount > 1 && (
                        <div style={style("right")}>
                            {`${errorCount} ${declensions[getDeclension(errorCount)]}`}
                        </div>
                        )}
                    </div>
                </Notification>
                )}
            </Slide>
        </NotificationGroup>
    )
}

export default ErrorNotification;