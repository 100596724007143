import { useDispatch } from "react-redux";

import { useLocalization, useInternationalization } from "@progress/kendo-react-intl";
import { Tooltip } from "@progress/kendo-react-tooltip";
import { Grid, GridColumn as Column } from "@progress/kendo-react-grid";

import { TOTAL, DATE_PARTS_WEEK } from "../../../../infrastructure/localizationKeys";
import { getPositiveNegativeColor, getBlackWhiteColor } from "../../../../infrastructure/gradientPercentToColor";
import { ToolTipedCell } from "../../../views/DataGridCells";
import { equityAddPeriod } from "../../../../store/reducers/equityReducer/creators";
import { PeriodTypes } from "../../../../infrastructure/enums/periodTypes";
import { dateToString } from "../../../../infrastructure/extensions";

const uperCaseFirst = (str) => str[0].toUpperCase() + str.slice(1);
const getWeekdayLocalizationKey = (day) => day === "total" ? TOTAL : `Common_Weekday_${uperCaseFirst(day)}`;
const TooltipContentTemplate = (props) => <pre>{props.title}</pre>;

const columns = [
    "monday",
    "tuesday",
    "wednesday",
    "thursday",
    "friday",
    "saturday",
    "sunday",
]

const WeeksTab = ({ data, weekend, height }) => {
    const dispatch = useDispatch();
    const intl = useInternationalization();
    const localization = useLocalization();

    const WeekCell = (props) => {
        const startDate = new Date(props.dataItem["firstDateOfWeek"]);
        const endDate = new Date(startDate);
        endDate.setDate(endDate.getDate() + 6);
        const year = startDate.getFullYear();
        const getFormateDate = (date) => intl.formatDate(date, "dd.MM");

        const handleAddWeekPeriod = () => {
            dispatch(equityAddPeriod(PeriodTypes.WeekTotal, dateToString(startDate), dateToString(endDate)));
        }

        return (
            <td onClick={handleAddWeekPeriod} className="cursor-pointer">
                {year}&nbsp;-&nbsp;
                {localization.toLanguageString(DATE_PARTS_WEEK)}&nbsp;
                {props.dataItem["weekNumber"]}&nbsp;
                {`(${getFormateDate(startDate)}-${getFormateDate(endDate)})`}
            </td>
        )
    }

    const resultColumns = [...columns.slice(0, weekend ? 7 : 5), "total"];

    const handleAddDayPeriod = (start, day, value) => {
        if (!value)
            return;

        if (day + 1 === resultColumns.length)
            return;

        const startDay = new Date(start);
        startDay.setDate(startDay.getDate() + day);
        const endDay = new Date(startDay);
        endDay.setDate(endDay.getDate() + 1);
        dispatch(equityAddPeriod(PeriodTypes.Day, dateToString(startDay), dateToString(endDay)));
    }
    return (
        <Tooltip openDelay={100} anchorElement="target" content={TooltipContentTemplate}>
            <Grid data={data} style={{ height: height }}>
                <Column
                    title={localization.toLanguageString(DATE_PARTS_WEEK)}
                    cell={WeekCell}
                    width={270}
                />
                {resultColumns.map((x, i) =>
                    <Column
                        key={x}
                        field={`${x}String`}
                        title={localization.toLanguageString(getWeekdayLocalizationKey(x))}
                        cell={props => {
                            const offset = props.dataItem[`${x}CellGradientOffset`];
                            const color = getBlackWhiteColor(offset)
                            const background = getPositiveNegativeColor(offset);

                            return (
                                <ToolTipedCell
                                    {...props}
                                    className="cursor-pointer"
                                    style={{ color, background }}
                                    tooltip={`${x}ToolTip`}
                                    onClick={() => handleAddDayPeriod(props.dataItem["firstDateOfWeek"], i, props.dataItem[props.field])}
                                />
                            )
                        }}
                    />
                )}
            </Grid>
        </Tooltip>
    )
}

export default WeeksTab;