import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { useLocalization } from "@progress/kendo-react-intl";

import { LOADER } from "../../../infrastructure/localizationKeys";
import LoaderComponent from "../../views/LoaderComponent";
import { correlationLoad, correlationPropertiesUpdate } from "../../../store/reducers/correlationReducer/creators";
import { getPositiveNegativeColor } from "../../../infrastructure/gradientPercentToColor";
import CorrelationProperties from "./CorrelationProperties";

const CorrelationM = () => {
    const dispatch = useDispatch();
    const localization = useLocalization();

    const loaded = useSelector(state => state.correlation.loaded);
    const loading = useSelector(state => state.correlation.loading);
    const transparentLoader = useSelector(state => state.correlation.transparentLoader);
    const data = useSelector(state => state.correlation.correlations);
    const properties = useSelector(state => state.correlation.properties);

    let correlationsElements = [];

    data.forEach(element => {
        element.listInstrumentCoeffCorrelation
            .forEach(x => {
                const obj = {
                    firstSymbol: element.header,
                    lastSymbol: x.name,
                    value: x.value
                }
                if (correlationsElements.find(z => z.firstSymbol === obj.firstSymbol && z.lastSymbol === obj.lastSymbol && z.value === obj.value ||
                    z.firstSymbol === obj.lastSymbol && z.lastSymbol === obj.firstSymbol && z.value === obj.value) === undefined)
                    correlationsElements.push(obj);
            })

    })

    correlationsElements = correlationsElements.sort((a, b) => a.value > b.value ? -1 : 1);

    useEffect(() => {
        if (!loaded && !loading)
            dispatch(correlationLoad());
    });

    const style = {
        marginTop: "5px",
        border: "1px solid darkgrey",
        borderRadius: "5px",
        padding: "10px 10px 5px 10px"
    }

    const styeLeft = {
        color: "dodgerblue",
        textAlign: "left",
        width: "60%",
        paddingBottom: "5px"
    }

    const styeRight = {
        textAlign: "right",
        width: "40%",
        paddingBottom: "5px"
    }

    const handleUpdate = (properties) => {
        dispatch(correlationPropertiesUpdate(properties));
    }

    return (loading
        ? <LoaderComponent description={localization.toLanguageString(LOADER)} />
        : (<>
            {transparentLoader ? <div className="loader-over"> <LoaderComponent description={localization.toLanguageString(LOADER)} /></div> : null}
            <CorrelationProperties data={properties} onUpdate={handleUpdate} />
            <div style={{ marginTop: "-10px" }}>
                {correlationsElements.map((x, i) => {
                    const colorValue = {
                        color: getPositiveNegativeColor(x.value)
                    }
                    return (
                        <div style={style} key={i}>
                            <label style={styeLeft}>
                                {x.firstSymbol} <b>⋅</b> {x.lastSymbol}
                            </label>
                            <label style={styeRight}>
                                <label style={colorValue}>
                                    {x.value}
                                </label>
                            </label>
                        </div>)
                })}
            </div>
        </>
        )
    );
}

export default CorrelationM;