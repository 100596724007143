import Field from "./Field"

const ObjectEditor = (props) => {
    const className = `${props.orientation === "horizontal" ? "row" : ""}`

    const data = props.data;
    
    const onPropertyEdit = (name, value) => {
        const result = data.map(x => x.propertyName === name ? { ...x, value } : { ...x });
        props.onUpdate(result);
    }

    return (
        props.page === "payoutsM" && data.length > 0
            ? <div className={className}>
                <Field
                    {...data[0]}
                    key={data[0]?.propertyName}
                    onPropertyEdit={onPropertyEdit}
                    disabled={props.disabled}
                    orientation={props.orientation}
                    width={props.width}
                />
                <Field
                    {...data[5]}

                    key={data[5]?.propertyName}
                    onPropertyEdit={onPropertyEdit}
                    disabled={props.disabled}
                    orientation={props.orientation}
                    width={props.width}
                />
                {data.map((x, index) => index == 0 || index == 5 ? null :
                    <Field
                        {...x}
                        key={x.propertyName}
                        onPropertyEdit={onPropertyEdit}
                        disabled={props.disabled}
                        orientation={props.orientation}
                        width={props.width}
                    />
                )}
            </div>
            : <div className={className}>
                {data.map(x =>
                    <Field
                        {...x}
                        key={x.propertyName}
                        onPropertyEdit={onPropertyEdit}
                        disabled={props.disabled}
                        orientation={props.orientation}
                        width={props.width}
                        step={props.step}
                        maxvalue={props.maxvalue}
                        minvalue={props.minvalue}
                    />
                )}
            </div>
    )
}
export default ObjectEditor;