import { useState, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";

import { Grid, GridColumn as Column, getSelectedState } from "@progress/kendo-react-grid";
import { getter } from "@progress/kendo-react-common";


import { loadFuncUsage } from "../../../store/reducers/usageReducer/creators";

const DATA_ITEM_KEY = "function";
const SELECTED_FIELD = "selected";
const idGetter = getter(DATA_ITEM_KEY);


const UsageGrid = () => {
    const dispatch = useDispatch();
    const data = useSelector(state => state.usage.usage);

    const [selectedState, setSelectedState] = useState({});

    const itemSelect = (data) => {
        dispatch(loadFuncUsage(data.dataItem.function));
    }

    console.log(data);

    const onSelectionChange = useCallback(
        (event) => {
            const newSelectedState = getSelectedState({
                event,
                selectedState: selectedState,
                dataItemKey: DATA_ITEM_KEY,
            });
            setSelectedState(newSelectedState);
        }
    );

    return (
        <Grid
            className="admin-table"
            
            data={data.map(item => ({ ...item, [SELECTED_FIELD]: selectedState[idGetter(item)] }))}

            dataItemKey={DATA_ITEM_KEY}
            selectedField={SELECTED_FIELD}
            selectable={{
                enabled: true,
                drag: false,
                cell: false,
                mode: "single",
            }}
            onSelectionChange={onSelectionChange}


            onRowClick={itemSelect}
        >
            <Column field="function" title="Func" />
            <Column field="count" title="Count" />
        </Grid>
    )
}

export default UsageGrid;