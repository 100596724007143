import { useState } from "react";

import { useLocalization } from "@progress/kendo-react-intl";
import { Grid, GridColumn as Column } from "@progress/kendo-react-grid";
import { orderBy } from "@progress/kendo-data-query";

import { TradeActionCell, DateTimeCell, QuantityCell, NumberCell } from "../../../views/DataGridCells";

import { TIME, ACCOUNT, ACTION, QUANTITY, PRICE, AVG_POSITION_PRICE, REALIZED_PL, EXECUTION_OPEN_QUANTITY } from "../../../../infrastructure/localizationKeys";

const NumberColorCell = (props) => {
    return <NumberCell {...props} colored={true} />
}

const PortfolioExecutions = (props) => {
    const localization = useLocalization();
    const [sort, setSort] = useState([]);
    const sortChange = (event) => setSort(event.sort);

    return (
        <Grid
            data={orderBy(props.data, sort)}
            onSortChange={sortChange}
            sort={sort}
            sortable={true}
            style={{ height: "300px" }}
        >
            <Column field="DateTime" title={localization.toLanguageString(TIME)} cell={DateTimeCell} />
            <Column field="Number" title={localization.toLanguageString(ACCOUNT)} />
            <Column field="TradeAction" title={localization.toLanguageString(ACTION)} cell={TradeActionCell} />
            <Column field="Quantity" title={localization.toLanguageString(QUANTITY)} cell={QuantityCell} />
            <Column field="Price" title={localization.toLanguageString(PRICE)} cell={NumberCell} />
            <Column field="AveragePositionPrice" title={localization.toLanguageString(AVG_POSITION_PRICE)} cell={NumberCell} />
            <Column field="RealizedPl" title={localization.toLanguageString(REALIZED_PL)} cell={NumberColorCell} />
            <Column field="OpenQuantity" title={localization.toLanguageString(EXECUTION_OPEN_QUANTITY)} cell={NumberCell} />
        </Grid>
    );
}

export default PortfolioExecutions;