////import { useEffect } from "react";
import { useSelector, /*useDispatch*/ } from "react-redux";

//import { useLocalization } from "@progress/kendo-react-intl";

//import { LOADER } from "../../../../infrastructure/localizationKeys";

import StatementsTable from "./StatementsTable";
//import LoaderComponent from "../../../views/LoaderComponent";

//import { loadAccounts } from "../../../../store/reducers/accountsReducer/creators";

const Account = (props) => {
    //const dispatch = useDispatch();
    const id = props?.data;
    const data = useSelector(state => state.accounts.data);
    //const loaded = useSelector(state => state.accounts.loaded);
    //const loading = useSelector(state => state.accounts.loading);
    //const localization = useLocalization();
    const account = data.find(x => x.id === id);
    
    //useEffect(() => {
    //    if (!loading && !loaded)
    //        dispatch(loadAccounts());
    //})

    return <StatementsTable data={account.statements} />

//    return loaded ? (
//        <StatementsTable data={account.statements} />
//    ) : (
//        <LoaderComponent description={localization.toLanguageString(LOADER)} />
//    )
}

export default Account;