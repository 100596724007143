import { isMobile } from 'react-device-detect';

import PortfolioD from "./PortfolioD";
import PortfolioM from "./PortfolioM";

const Portfolio = () => {
    return (
        isMobile ? <PortfolioM /> : <PortfolioD />
    );
}

export default Portfolio;