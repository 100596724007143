import {
    SIGN_IN, REGISTER,
    LOGIN_LOGOUT_CLEAR_ERROR, LOGIN_LOGOUT_ERROR, LOGIN_LOGOUT_SUCCESS,
    REMOVE_TOKEN_FROM_STORAGE, CHECK_VALIDATION_SUCCESS,
    LOGIN, DEMO,
} from "./actions";

export const demo = () => ({
    type: DEMO
})

export const loginLogutError = (error) => ({
    type: LOGIN_LOGOUT_ERROR,
    payload: error,
});

export const loginLogoutSuccess = (data) => ({
    type: LOGIN_LOGOUT_SUCCESS,
    payload: data,
});

export const removeToken = () => ({
    type: REMOVE_TOKEN_FROM_STORAGE
});

export const checkValidationSuccess = (data) => ({
    type: CHECK_VALIDATION_SUCCESS,
    payload: data
});

export const login = (token, role, name, connection) => ({
    type: LOGIN,
    payload: { token, role, name, connection }
});

export const signIn = (data) => ({
    type: SIGN_IN,
    payload: data
})

export const register = (data) => ({
    type: REGISTER,
    payload: data
})

export const clearError = () => ({
    type: LOGIN_LOGOUT_CLEAR_ERROR,
})