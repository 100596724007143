import { useSelector } from "react-redux";

import "hammerjs";

import {
    StockChart,
    ChartSeries,
    ChartSeriesItem,
    ChartNavigator,
    ChartNavigatorSeries,
    ChartNavigatorSeriesItem,
    ChartValueAxis,
    ChartValueAxisItem,
    ChartTooltip,
} from "@progress/kendo-react-charts";

import { useInternationalization } from "@progress/kendo-react-intl";

const ChartMobile = ({ height }) => {
    const intl = useInternationalization();
    const equity = useSelector(state => state.equity.equity);
    const moex = useSelector(state => state.equity.benchmark);
    const inflation = useSelector(state => state.equity.inflation);

    const expandMenu = useSelector(state => state.general.expandMenu);  //Used to resize the chart. Fires when the sidebar view changes.

    const numericIntFormat = new Intl.NumberFormat("en", { style: "decimal", maximumFractionDigits: 2 });
    const numericFormat = new Intl.NumberFormat("en", { style: "decimal", minimumFractionDigits: 2, maximumFractionDigits: 2 });

    const labelContent = (e) => numericIntFormat.format(e.value);

    const renderTooltip = ({ point }) => (
        <span>
            {intl.formatDate(point.category, "EE, dd MMMM yyyy")}: <strong style={{ color: "black" }}>{numericFormat
                .format(point.value)}</strong>
        </span>
    );

    const style = {
        height: height,
        minHeight: "250px",
        width: "90%"
}

    return (<div style={{ marginTop: "-20px" }}>
        <StockChart className="k-mt-3 chart-equity" style={style} transitions={false}>

            <ChartValueAxis>
                <ChartValueAxisItem labels={{ content: labelContent }} />
            </ChartValueAxis>

            <ChartSeries>
                <ChartSeriesItem
                    type="line"
                    data={equity}
                    markers={{
                        visible: false,
                    }}
                    field="endEquity"
                    categoryField="date"
                    color="DodgerBlue"
                />
                <ChartSeriesItem
                    type="line"
                    data={moex}
                    markers={{
                        visible: false,
                    }}
                    field="value"
                    categoryField="date"
                    color="Red"
                />
                <ChartSeriesItem
                    type="line"
                    data={inflation}
                    markers={{
                        visible: false,
                    }}
                    field="value"
                    categoryField="date"
                    color="DarkRed"
                />
            </ChartSeries>

            <ChartTooltip render={renderTooltip} />
        </StockChart>
        </div>
    )
}

export default ChartMobile;