import { isMobile } from 'react-device-detect';

import CorrelationD from "./CorrelationD";
import CorrelationM from "./CorrelationM";

const Correlation = () => {
    return (
        isMobile ? <CorrelationM /> : <CorrelationD />
    );
}

export default Correlation;