import { Grid, GridColumn as Column } from "@progress/kendo-react-grid";
import { useLocalization } from "@progress/kendo-react-intl";

import { ACCOUNT, QUANTITY, AVERAGE_PRICE } from "../../../../infrastructure/localizationKeys";

const PortfolioAccounts = (props) => {
    const localization = useLocalization();

    return(
        <Grid data={props.data} style={{ height: "300px" }}>
            <Column field="account.Number" title={localization.toLanguageString(ACCOUNT)} />
            <Column field="signedQuantity" title={localization.toLanguageString(QUANTITY)} />
            <Column field="averagePriceDecorator" title={localization.toLanguageString(AVERAGE_PRICE)} />
        </Grid>
    );
}

export default PortfolioAccounts;