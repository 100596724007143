export const SIGN_IN = "SIGN_IN";
export const REGISTER = "REGISTER";

export const LOGIN = "LOGIN";

export const LOGIN_LOGOUT_SUCCESS = "LOGIN_LOGOUT_SUCCESS"

export const LOGIN_LOGOUT_ERROR = "LOGIN_LOGOUT_ERROR";
export const LOGIN_LOGOUT_CLEAR_ERROR = "LOGIN_LOGOUT_CLEAR_ERROR";

export const REMOVE_TOKEN_FROM_STORAGE = "REMOVE_TOKEN_FROM_STORAGE";

export const CHECK_VALIDATION = "CHECK_VALIDATION";
export const CHECK_VALIDATION_SUCCESS = "CHECK_VALIDATION_SUCCESS";

export const DEMO = "DEMO_ACCOUNT";

