import { fork, all, takeEvery } from 'redux-saga/effects';
import {
    loadIdeasSuccess, loadIdeasError,
    addIdeaSuccess, updateIdeaSuccess,
    addNoteSuccess, updateNoteSuccess,
    deactivateIdeaSuccess
} from "../../reducers/ideasReducer/creators";
import {
    IDEAS_LOAD_DATA, ADD_IDEA, IDEA_UPDATE,
    IDEA_ADD_NOTE, UPDATE_NOTE,
    DEACTIVATE_IDEA
} from "../../reducers/ideasReducer/actions"
import { requset } from "../infrastructure";

const baseUrl = "/api/ideas";
const ideaUrl = (idIdea) => `${baseUrl}/${idIdea}`;
const noteUrl = (idIdea) => `${ideaUrl(idIdea)}/notes`;
const updateNoteUrl = (idIdea, idNote) => `${noteUrl(idIdea)}/${idNote}`;

// WORKER SAGAS
function* load() {
    yield requset(baseUrl, "GET", loadIdeasSuccess, loadIdeasError);
}
function* addIdea({ payload }) {
    yield requset(baseUrl, "POST", addIdeaSuccess, null, payload);
}
function* updateIdea({ payload }) {
    const { idIdea, parameters } = payload;
    yield requset(ideaUrl(idIdea), "PATCH", updateIdeaSuccess, null, parameters);
}
function* addNote({ payload }) {
    const { idIdea, text } = payload;
    yield requset(noteUrl(idIdea), "POST", addNoteSuccess, null, text);
}
function* updateNote({ payload }) {
    const { idIdea, idNote, text } = payload;
    yield requset(updateNoteUrl(idIdea, idNote), "PUT", updateNoteSuccess, null, text);
}
function* deactivateIdea({ payload }) {
    yield requset(ideaUrl(payload), "PUT", deactivateIdeaSuccess);
}

// WATCHER SAGAS
function* loadSaga() {
    yield takeEvery(IDEAS_LOAD_DATA, load);
}
function* addIdeaSaga() {
    yield takeEvery(ADD_IDEA, addIdea);
}
function* updateIdeaSaga() {
    yield takeEvery(IDEA_UPDATE, updateIdea);
}
function* addNoteSaga() {
    yield takeEvery(IDEA_ADD_NOTE, addNote);
}
function* updateNoteSaga() {
    yield takeEvery(UPDATE_NOTE, updateNote);
}
function* deactivateIdeaSaga() {
    yield takeEvery(DEACTIVATE_IDEA, deactivateIdea);
}

// ROOT SAGA
export default function* dashboardSaga() {
    yield all([
        fork(loadSaga),

        fork(addIdeaSaga),
        fork(updateIdeaSaga),

        fork(addNoteSaga),
        fork(updateNoteSaga),

        fork(deactivateIdeaSaga)
    ]);
}