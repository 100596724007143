import { ListView } from "@progress/kendo-react-listview";
import { isMobile } from 'react-device-detect';

const ItemRender = (props) => {
    let item = props.dataItem;
    return (
        <div
            className="k-listview-item"
            style={{
                padding: 2,
                borderBottom: "1px solid lightgrey"
            }}
        >
            <div className="block-legend">
                <div className="color-legend">
                    <div style={{
                        background: item.color,
                        height: "15px",
                        width: "15px"
                    }} />
                </div>
                <div className="text-legend">
                    {item.name}
                </div>
            </div>
        </div>
    );
};

const ListLegend = ({ data }) => {
    const classNameList = isMobile ? "" : "list-legend-height";
    return (
        <ListView
            data={data}
            item={ItemRender}
            className={classNameList} />
    );
}

export default ListLegend;