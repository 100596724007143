import { useDispatch, useSelector } from "react-redux";
import { Grid, GridColumn as Column } from "@progress/kendo-react-grid";
import { useLocalization } from "@progress/kendo-react-intl";
import { useState } from "react";
import { orderBy } from "@progress/kendo-data-query";
import { Button } from '@progress/kendo-react-buttons';
import { Dialog } from "@progress/kendo-react-dialogs";

import { restorePositionsPropertiesUpdate } from "../../../store/reducers/restorePositionsReducer/creators";
import ObjectEditor from "../../views/ObjectEditor";
import { TradeActionCell, QuantityCell, PercentCell, RightAlignCell, DateTimeCell } from "../../views/DataGridCells";

import {
    NAME, QUANTITY, LAST_PRICE, ACCOUNT, ACTION, TABLE_HEADER_ACTIONS, INFO_CLOSE_POSITION,
    HEADER_EXECUTION_TIME, HEADER_REMAIN, HEADER_REALIZED_LOSS, HEADER_UNCOVERED_LOSS, HEADER_EXECUTION_PRICE, HEADER_PRICE_CHANGE, BTN_BUY
}
    from "../../../infrastructure/localizationKeys";

const PercentColorCell = (props) => {
    return <PercentCell {...props} colored={true} />
}

const RestorePositionsTable = () => {
    const dispatch = useDispatch();
    const localization = useLocalization();

    const data = useSelector(state => state.restorePositions.data);
    const properties = useSelector(state => state.restorePositions.properties);
    
    const [visibleModalInfo, setVisibleModalInfo] = useState(false);

    const initialSort = [
        {
            field: "quantity",
            dir: "desc",
        },
    ];

    const [sort, setSort] = useState(initialSort);

    const handleUpdate = (properties) => {
        dispatch(restorePositionsPropertiesUpdate(properties))
    }

    const toggleDialogDelete = () => {
        setVisibleModalInfo(!visibleModalInfo);
    };

    const DialogModalInfo = () => {
        return (
            visibleModalInfo &&
            <Dialog title="" modal={true} onClose={toggleDialogDelete} resizable={true} width={550}>
                <h5>{localization.toLanguageString(INFO_CLOSE_POSITION)}</h5>
                <Button onClick={toggleDialogDelete} className="btn-dialog">
                    OK
                </Button>
            </Dialog>
        );
    }

    const styleCell = {
        display: "inline-block",
        width: "-webkit-fill-available"
    }

    const styleBtn = {
        height: "35px",
        width: "inherit"
    }

    const viewModalInfo = () => {
        setVisibleModalInfo(true);
    }

    const ButtonCell = () => {
        return (
            <td style={styleCell}>
                <Button style={styleBtn} onClick={viewModalInfo}>{localization.toLanguageString(BTN_BUY)}</Button>
            </td>
        );
    }

    const sortChange = (event) => setSort(event.sort);

    return (
        <div>
            <ObjectEditor data={properties} onUpdate={handleUpdate} orientation="horizontal" />
            <Grid
                data={orderBy(data, sort)}
                className="profit-fixer-table"
                onSortChange={sortChange}
                sort={sort}
                sortable={true}
            >
                <Column title={localization.toLanguageString(NAME)} field="instrument.Name" />
                <Column title={localization.toLanguageString(ACCOUNT)} field="account.Number" />
                <Column title={localization.toLanguageString(ACTION)} field="tradeAction" cell={TradeActionCell} />
                <Column title={localization.toLanguageString(HEADER_EXECUTION_TIME)} field="executionTime" cell={DateTimeCell} width={170} />
                <Column title={localization.toLanguageString(QUANTITY)} field="quantity" cell={QuantityCell} />
                <Column title={localization.toLanguageString(HEADER_REMAIN)} field="leftQuantity" cell={QuantityCell} />
                <Column title={localization.toLanguageString(HEADER_REALIZED_LOSS)} field="realizedLossDecorator" cell={RightAlignCell} />
                <Column title={localization.toLanguageString(HEADER_UNCOVERED_LOSS)} field="uncoveredLossDecorator" cell={RightAlignCell} />
                <Column title={localization.toLanguageString(HEADER_EXECUTION_PRICE)} field="executionPriceDecorator" cell={RightAlignCell} />
                <Column title={localization.toLanguageString(HEADER_PRICE_CHANGE)} field="priceChange" cell={PercentColorCell} />
                <Column title={localization.toLanguageString(LAST_PRICE)} field="marketPriceDecorator" cell={RightAlignCell} />
                <Column title={localization.toLanguageString(TABLE_HEADER_ACTIONS)} cell={ButtonCell} width={120} />
            </Grid>
            <DialogModalInfo />
        </div>
    );
}

export default RestorePositionsTable;