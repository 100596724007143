import { isMobile } from 'react-device-detect';

import LoginD from "./LoginD";
import LoginM from "./LoginM";

const Login = () => {
    return (
        isMobile ? <LoginM /> : <LoginD />
    );
}

export default Login;