import { useDispatch, useSelector } from "react-redux";

import { useLocalization } from "@progress/kendo-react-intl";
import { DateRangePicker } from "@progress/kendo-react-dateinputs";
import { Button } from "@progress/kendo-react-buttons";

import { equityPeriodUpdate, equityAddPeriod } from "../../../../store/reducers/equityReducer/creators";
import { EQUITY_REPORT_CUSTOM_PERIOD, EQUITY_OPEN_CUSTOM_PERIOD } from "../../../../infrastructure/localizationKeys";
import { PeriodTypes } from "../../../../infrastructure/enums/periodTypes";
import { dateToString } from "../../../../infrastructure/extensions";

const AddCustomPeriodTab = (props) => {
    const localization = useLocalization();
    const dispatch = useDispatch();
    const start = useSelector(state => state.equity.start);
    const end = useSelector(state => state.equity.end);

    const period = {
        start: new Date(start),
        end: end ? new Date(end) : null
    }
    const handlePeriodChange = (event) => {
        dispatch(equityPeriodUpdate({
            start: dateToString(event.value.start),
            end : dateToString(event.value.end)
        }));
    }

    const handleAddPeriod = () => {
        dispatch(equityAddPeriod(PeriodTypes.Custom, start, end));
    }

    return (
        <div style={{ height: props.height }}>
            <h6>{localization.toLanguageString(EQUITY_REPORT_CUSTOM_PERIOD)}</h6>
            <DateRangePicker value={period} onChange={handlePeriodChange} />
            <Button
                onClick={handleAddPeriod}
                className="k-mt-3"
            >
                {localization.toLanguageString(EQUITY_OPEN_CUSTOM_PERIOD)}
            </Button>
        </div>
    )
}

export default AddCustomPeriodTab;