import {
    LOAD_USERS, LOAD_USERS_SUCCESS,
    UPDATE_USER, UPDATE_USER_SUCCESS,
    DELETE_USER_SUCCESS,
    EDIT_USER_ENTER, EDIT_USER, CANCEL_EDIT_USER,
} from "./actions";

import { LOGOUT } from "../generalReducer/generalActions";

const initial = {
    list: [],
    loading: false,
    loaded: false,
    editingUser: {}
};

function usersReducer(state = initial, action) {
    switch (action.type) {

        // LOAD USERS
        case LOAD_USERS:
            return {
                ...state,
                loading: true,
            }
        case LOAD_USERS_SUCCESS:
            return {
                ...state,
                loading: false,
                loaded: true,
                list: action.payload
            }

        // UPDATE USER
        case UPDATE_USER:
        {
            const { id } = action.payload;
            return {
                ...state,
                list: state.list.map((item) => item.id === id ?
                    { ...item, inEdit: false } :
                    item)
            }
        }
        case UPDATE_USER_SUCCESS:
            return {
                ...state,
                list: state.list.map(x => x.id === action.payload.id ? action.payload : x)
            }

        // DELETE USER
        case DELETE_USER_SUCCESS:
            return {
                ...state,
                list: state.list.filter(x => x.id != action.payload)
            }

        // EDIT USER
        case EDIT_USER_ENTER:
            return {
                ...state,
                editingUser: { ...state.list.find(item => item.id == action.payload) },
                list: state.list.map((item) => ({ ...item, inEdit: item.id === action.payload}))
            }
        case CANCEL_EDIT_USER:
            return {
                ...state,
                list: state.list.map((item) => item.id === action.payload? {...state.editingUser } : item)
            }
        case EDIT_USER:
            {
                const { id, field, value } = action.payload;
                return {
                    ...state,
                    list: state.list.map((item) => item.id === id ?
                        { ...item, [field]: value } :
                        item)
                }
            }

        // GENERAL
        case LOGOUT:
            return initial;
        default:
            return state;
    }
}

export default usersReducer;